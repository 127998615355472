import {
  ActionTypes as BotTrafficActionTypes,
  Constants as BotTrafficConstants,
  Actions as BotTrafficActions,
} from './botTraffic'
import {
  ActionTypes as ClientActionTypes,
  Constants as ClientConstants,
  Actions as ClientActions,
} from './client'
import {
  ActionTypes as DataLayerActionTypes,
  Constants as DataLayerConstants,
  Actions as DataLayerActions,
} from './dataLayer'
import {
  ActionTypes as DisableEpicsActionTypes,
  Constants as DisableEpicsConstants,
  Actions as DisableEpicsActions,
} from './disableEpics'
import {
  ActionTypes as FeatureFlagsActionTypes,
  Constants as FeatureFlagsConstants,
  Actions as FeatureFlagsActions,
} from './featureFlags'
import {
  ActionTypes as FlashMessageActionTypes,
  Constants as FlashMessageConstants,
  Actions as FlashMessageActions,
} from './flashMessage'
import {
  ActionTypes as FormActionTypes,
  Constants as FormConstants,
  Actions as FormActions,
} from './form'
import {
  ActionTypes as InstanaActionTypes,
  Constants as InstanaConstants,
  Actions as InstanaActions,
} from './instana'
import {
  ActionTypes as LocaleActionTypes,
  Constants as LocaleConstants,
  Actions as LocaleActions,
} from './locale'
import {
  ActionTypes as LocationActionTypes,
  Constants as LocationConstants,
  Actions as LocationActions,
} from './location'
import {
  ActionTypes as MobileTrafficActionTypes,
  Constants as MobileTrafficConstants,
  Actions as MobileTrafficActions,
} from './mobileTraffic'
import {
  ActionTypes as ModalActionTypes,
  Constants as ModalConstants,
  Actions as ModalActions,
} from './modal'
import {
  ActionTypes as NavigationActionTypes,
  Constants as NavigationConstants,
  Actions as NavigationActions,
} from './navigation'
import { ActionTypes as QAActionTypes, Constants as QAConstants, Actions as QAActions } from './qa'
// CONSTANTS
export const Constants = {
  ...BotTrafficConstants,
  ...ClientConstants,
  ...DataLayerConstants,
  ...DisableEpicsConstants,
  ...FeatureFlagsConstants,
  ...FlashMessageConstants,
  ...FormConstants,
  ...InstanaConstants,
  ...LocaleConstants,
  ...LocationConstants,
  ...MobileTrafficConstants,
  ...ModalConstants,
  ...NavigationConstants,
  ...QAConstants,
}
// ACTION TYPES
export type ActionTypes =
  | BotTrafficActionTypes
  | ClientActionTypes
  | DataLayerActionTypes
  | DisableEpicsActionTypes
  | FeatureFlagsActionTypes
  | FlashMessageActionTypes
  | FormActionTypes
  | InstanaActionTypes
  | LocationActionTypes
  | LocaleActionTypes
  | MobileTrafficActionTypes
  | ModalActionTypes
  | NavigationActionTypes
  | QAActionTypes
// ACTION CALLS
export const Actions = {
  ...BotTrafficActions,
  ...ClientActions,
  ...DataLayerActions,
  ...DisableEpicsActions,
  ...FeatureFlagsActions,
  ...FlashMessageActions,
  ...FormActions,
  ...InstanaActions,
  ...LocationActions,
  ...LocaleActions,
  ...MobileTrafficActions,
  ...ModalActions,
  ...NavigationActions,
  ...QAActions,
}
