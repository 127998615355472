import easelAPI, { EaselResponse, getOptions, postOptions } from 'apis/helpers/easelAPI'
import legacyAPI, { LegacyResponse } from 'apis/helpers/legacyAPI'
import { deleteCartStorage } from 'components/CartUrgencyBar/helper'
import { resetClientStorage } from '../store/middleware/client'

type LoginResponse = {
  ip: string
  success: boolean
  userEmail: string
  firstName: string
  userId: string
  userType: string
  messages: string[]
}

/* Authentication */
export const postLogin = async (
  payload: Record<string, any>,
  userPreferencesCountryCode: string
): Promise<LoginResponse> => {
  const formOptions = {
    ...postOptions,
    headers: { ...postOptions.headers, 'X-Preferred-Locale': `en-${userPreferencesCountryCode}` },
    body: JSON.stringify({
      remember: true,
      ...payload,
    }),
  }
  const json = await legacyAPI.fetchJSON({
    name: 'fetchLogin',
    formOptions,
    url: '/auth-api/login',
  })
  const {
    success,
    user_id: userId,
    user_email: userEmail,
    user_type: userType,
    first_name: firstName,
    ip,
    messages,
  } = json
  return {
    ip: ip || '',
    success: success || false,
    userEmail: userEmail || '',
    firstName: firstName || '',
    userId: String(userId || ''),
    userType: (userType || '').toLowerCase(),
    messages: messages || [],
  }
}

export const fetchCheckFacebookConnect = async (
  userPreferencesCountryCode: string
): Promise<LegacyResponse> => {
  const formOptions = {
    ...getOptions,
    headers: { 'X-Preferred-Locale': `en-${userPreferencesCountryCode}` },
  }
  const json = await legacyAPI.fetchJSON({
    name: 'fetchCheckFacebookConnect',
    formOptions,
    url: '/check-facebook-connect',
  })
  return json
}

export const postRegister = async (payload: Record<string, any>): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const json = await easelAPI.fetchJSON({
    name: 'postRegister',
    options,
    url: '/auth/register',
  })
  return json
}

export const postResetPassword = async (payload: Record<string, any>): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const json = await easelAPI.fetchJSON({
    name: 'postResetPassword',
    options,
    url: '/auth/reset-password',
  })
  return json
}

export const postNewPassword = async (payload: Record<string, any>): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify({ ...payload }) }
  const json = await easelAPI.fetchJSON({
    name: 'postNewPassword',
    options,
    url: '/auth/new-password',
  })
  return json
}

export const getLogout = async (): Promise<EaselResponse> => {
  // Session Data - user data
  resetClientStorage()
  // Set local storage to check cart urgency flash message
  deleteCartStorage()
  const options = getOptions
  const json = await easelAPI.fetchJSON({
    name: 'getLogout',
    options,
    url: '/auth/logout',
  })
  return json
}
