// We need to SELECT theme based on site context
import React from 'react'
import { ThemeProvider } from 'styled-components'
import SiteContextConnect from 'containers/SiteContext'
import { limited, saatchiart } from 'lib/theme'
import { SiteContext } from 'types/SiteContext'

type Props = {
  siteContext: SiteContext
  children: React.ReactNode
}

function Theme(props: Props): React.ReactElement<React.ComponentProps<any>, any> {
  const { children, siteContext } = props
  // Use siteContext from redux store to choose which Theme to use else default Saatchi
  return (
    <ThemeProvider theme={siteContext === 'limited' ? limited : saatchiart}>
      {children}
    </ThemeProvider>
  )
}

export default SiteContextConnect(Theme)
