import dynamic from 'next/dynamic'

export const Header = dynamic(
  import(
    /* webpackChunkName: "navigation" */
    'components/Header'
  )
)

export const StudioHeader = dynamic(
  import(
    /* webpackChunkName: "navigation" */
    'components/Header/Studio'
  )
)

export const Footer = dynamic(
  import(
    /* webpackChunkName: "navigation" */
    'components/Footer'
  )
)
