import React, { FC } from 'react'
import { fetchAllClientData } from 'apis/client'
import { createNewBrowserPath, getCurrentBrowserPath } from 'lib/storeLocale/client'
import { setClientStorage } from 'store/middleware/client'
import { getCartData } from 'components/CartUrgencyBar/helper'
import { ModalComponentProps } from './Auth'
import Login from '../Forms/Login'
import Modal from 'components/SA/Modal/v2'
import styles from './Auth.module.scss'

type LoginModalProps = {
  closeModal: () => void
  resetPasswordModal: () => void
  registerModal: () => void
  updateSession: ({ cart, cookie, session }: { cart: number; cookie: any; session: any }) => void
}

export const MODAL_NAME = 'login'

const LoginModal: FC<ModalComponentProps> = (props) => {
  const { closeModal, resetPasswordModal, registerModal, updateSession } = props
  const handleSubmit = async () => {
    // Fetch Session and Cart data
    const { payload: allClientData, success } = await fetchAllClientData()

    if (!success) {
      console.error('Problem Fetching Session')
      return
    }

    setClientStorage(allClientData)
    await getCartData()
    const { cart, cookie, session } = allClientData
    const { countryCode } = cookie.userPreferences
    const urlPrefix = `en-${countryCode.toLowerCase()}`
    const newBrowserPath = createNewBrowserPath(urlPrefix)
    const currentBrowserPath = getCurrentBrowserPath()

    if (newBrowserPath !== currentBrowserPath) {
      window.location.href = newBrowserPath
      return
    }

    updateSession({
      cart: cart.count || 0,
      cookie,
      session,
    })
    closeModal()
  }

  return (
    <Modal
      name={MODAL_NAME}
      onCloseModal={closeModal}
      layout='standard'
      styleOverride={styles.authModal}
      isCloseButtonVisible
    >
      <>
        <Login
          onClickRegister={() => {
            registerModal()
          }}
          onClickResetPassword={() => {
            resetPasswordModal()
          }}
          onSubmit={handleSubmit}
        />
      </>
    </Modal>
  )
}

export default LoginModal
