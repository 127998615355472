import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserRole } from 'selectors/user/session'
import { getUserPreferencesCountryCode } from 'selectors/user/cookie'
import { getPromoCampaigns, getPromoCta, getPagePath } from 'selectors/user/promos'
const mapStateToProps = createStructuredSelector({
  promoCampaigns: getPromoCampaigns,
  promoCta: getPromoCta,
  countryCode: getUserPreferencesCountryCode,
  userRole: getUserRole,
  pageType: getPagePath,
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  promoCampaigns: Array<Record<string, any>>
  promoCta: Array<Record<string, any>>
  countryCode: string
  userRole: string
  pageType: string
}
export default connect(mapStateToProps, mapDispatchToProps)
