import React, { FC, useState } from 'react'
import classNames from 'classnames'

type InputLabelProps = {
  id?: string
  autoComplete?: string
  disabled?: boolean
  error?: string
  inputMode?: string
  maxLength?: number
  name: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onWheel?: React.WheelEventHandler<HTMLInputElement>
  pattern?: string
  placeholder?: string
  title: string
  readOnly?: boolean
  type?: string
  value: string | number | ReadonlyArray<string> | undefined
  styleOverride?: string
}

const InputLabel: FC<InputLabelProps> = (props) => {
  const {
    id,
    autoComplete,
    disabled,
    error,
    maxLength,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onWheel,
    placeholder,
    title, // placeHolderHover
    readOnly,
    type,
    value,
    styleOverride = '',
  } = props
  const placeholderText = placeholder ?? title

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = (ev) => {
    setIsFocused(true)
    onFocus?.(ev)
  }

  const handleBlur = (ev) => {
    setIsFocused(false)
    onBlur?.(ev)
  }

  return (
    <div
      className={classNames('defaultInputBox', {
        [styleOverride]: styleOverride !== '',
      })}
      data-field={name}
    >
      <input
        id={id}
        className={classNames('defaultInputField', {
          defaultInputFieldError: error,
        })}
        autoComplete={autoComplete}
        maxLength={maxLength}
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        onWheel={onWheel}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
        {...(readOnly
          ? {
              readOnly: true,
            }
          : {})}
        {...(disabled
          ? {
              disabled: true,
            }
          : {})}
      />

      <label className='defaultTextAreaFieldLabel' htmlFor={name} data-field={name}>
        {isFocused ? title : placeholderText}
      </label>

      {error && (
        <span className='defaultInputError' data-type='error'>
          {error}
        </span>
      )}
    </div>
  )
}

export default InputLabel
