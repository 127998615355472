import React, { FC } from 'react'
import UserPreferences, { ConnectorProps } from 'connectors/UserPreferences'
import { validateBrowserStoreLocale } from 'lib/storeLocale/client'
import { setClientStorage, setClientStorageCookie } from 'store/middleware/client'
import { fetchAllClientData } from 'apis/client'
import Modal from 'components/SA/Modal/v2'
import Preferences from '../Forms/Preferences'
import styles from './Preferences.module.scss'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'
import { setUserPreferencesCookie } from 'apis/cookies'

type PreferencesModalProps = ModalProps & ConnectorProps

export const MODAL_NAME = 'user-preferences-modal'

const PreferencesModal: FC<PreferencesModalProps> = (props) => {
  const {
    availableCountries,
    closeModal,
    currenciesDropdownData,
    isAuthenticated,
    modalData,
    updateClientData,
    userPreferences,
  } = props
  const { requiresConfirmation } = modalData

  const handleSubmit = async () => {
    // Fetch Session and Cart data
    const { payload: allClientData, success } = await fetchAllClientData()

    if (!success) {
      console.error('Problem Fetching Session')
      return
    }

    const { countryCode: userPreferencesCountryCode } = userPreferences
    setClientStorage(allClientData)
    updateClientData(allClientData) // update user preferences in the DB from session

    const { countryCode: newCountryCode, storeLocale } = allClientData.cookie.userPreferences
    const shouldRedirect = userPreferencesCountryCode !== newCountryCode

    if (shouldRedirect) {
      const newStoreLocale = `${`en-${newCountryCode.toLowerCase()}`}`
      validateBrowserStoreLocale(newStoreLocale, true)
      return
    }

    const redirectRequired = validateBrowserStoreLocale(storeLocale)
    if (redirectRequired) {
      return
    }

    closeModal()
  }

  const customCloseAction = async (ev) => {
    if (requiresConfirmation) {
      const { payload } = await setUserPreferencesCookie()
      setClientStorageCookie(payload)
      const { storeLocale } = payload
      const redirectRequired = validateBrowserStoreLocale(storeLocale)
      if (redirectRequired) {
        return
      }
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      name={MODAL_NAME}
      onCloseModal={customCloseAction}
      styleOverride={styles.preferencesModal}
    >
      <>
        <Preferences
          availableCountries={availableCountries}
          currenciesDropdownData={currenciesDropdownData}
          isAuthenticated={isAuthenticated}
          onSubmit={handleSubmit}
          requiresConfirmation={requiresConfirmation}
          userPreferences={userPreferences}
        />
      </>
    </Modal>
  )
}

export default ModalConnector(UserPreferences(PreferencesModal))
