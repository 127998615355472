import { addStoreLocaleByCookie } from 'lib/storeLocale/client'
const { APP_ENV } = process.env
const isDev = APP_ENV !== 'production'

export type APIResponseSuccess = {
  success: boolean
  payload: Record<string, any>
  loginRequired?: boolean
}
export type APIResponseError = {
  success: boolean
  payload: Record<string, any>
  loginRequired?: boolean
}
export type APIResponse = any | APIResponseSuccess | APIResponseError

export const jsonOptions = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    ...(isDev
      ? {
          'QA-TESTING': 'SAATCHI_DEVELOPER',
        }
      : {}),
  },
}

export const getOptions = { ...jsonOptions, method: 'GET' }

export const postOptions = { ...jsonOptions, method: 'POST' }

export const putOptions = { ...jsonOptions, method: 'PUT' }

export const patchOptions = { ...jsonOptions, method: 'PATCH' }

export const deleteOptions = { ...jsonOptions, method: 'DELETE' }

export const multipartFormDataOptions = {
  method: 'POST',
  credentials: 'include',
  headers: {
    ...(isDev
      ? {
          'QA-TESTING': 'SAATCHI_DEVELOPER',
        }
      : {}),
  }, // Remove Headers 'Content-Type' so BROWSER can post => `multipart/form-data; boundary=----WebKitFormBoundaryUrSzEQMCCTIBJBlK`
}

export const getIsBrowserAvailable = (): boolean => {
  const isBrowserAvailable = typeof window !== 'undefined'
  return isBrowserAvailable
}

// API Helper for making sure all Fetch calls handles JSON the same way
export async function parseJSON(
  response: Response,
  api: string | null | undefined,
  defaultResponse?: Record<string, any> | null | undefined
): Promise<APIResponse> {
  try {
    const { headers } = response
    // To Handle Failures and NOT Parse Failed Responses
    if (!headers.has('Content-Type')) {
      return (
        defaultResponse || {
          success: false,
          payload: {},
        }
      )
    }
    const json = await response.json()
    return json
  } catch (err) {
    console.error(`ParseJSON ( via APIs) Error ${api || 'API:Unknown'}:`, err)
    return (
      defaultResponse || {
        success: false,
        payload: {},
      }
    )
  }
}
// Legacy API calls for Form Data

export const createFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData()
  Object.keys(data).map((key) => {
    if (Array.isArray(data[key])) {
      return data[key].map((val) => formData.append(`${key}[]`, val))
    }

    return formData.append(key, data[key])
  })
  return formData
}
// This is used for FAILING apis, Redirect users to authentication

export const validateLoginStatus = (json: APIResponseSuccess | any): void => {
  if (json.loginRequired) {
    // Previously used encodeURIComponent, but double-escaped `&` characters
    // were not processing properly in the auth app and redirected to the homepage.
    const location = addStoreLocaleByCookie(
      `/authentication?redirect_url=${encodeURI(window.location.href)}`
    )
    window.location.href = location
  }
}
// We want to check if a session has ended, we will read the HEADERS if the `Authenticated-User` from Node Server
// Values are case sensitive and if you loop you will see values are LOWERCASE

export const checkAuthenticatedUserHeaders = (response: Response): boolean => {
  try {
    const { headers } = response
    if (headers && headers.get && headers.get('authenticated-user') === 'true') {
      return true
    }
    return false
  } catch (err) {
    console.error('checkAuthenticatedUserHeaders', { err })
    return false
  }
}

export const getErrorCodes = (json) => {
  return (json || []).reduce((results, { code }) => {
    return [...results, code]
  }, [])
}
