import React, { useEffect, useMemo } from 'react'
import FlashMessagesConnector, {
  ConnectorProps as FlashMessagesConnectorProps,
} from 'containers/FlashMessage'
import PromoBarConnector, { ConnectorProps as PromoBarConnectorProps } from 'connectors/PromoBar'
import { PromoWrapper } from './styles'
import { getCurrentPromoCampaignData } from './helpers'
import PromoCampaign from './PromoCampaign'
import PromoCta from './PromoCta'

type Props = FlashMessagesConnectorProps & PromoBarConnectorProps

const Component = (props: Props) => {
  const {
    addMessage,
    promoCampaigns,
    countryCode: localeCountryCode,
    userRole,
    promoCta,
    pageType,
  } = props
  const currentPromo = promoCampaigns
    ? getCurrentPromoCampaignData({
        localeCountryCode,
        promoCampaigns,
      })
    : ''
  const currentCTA = promoCta ? promoCta[userRole] || promoCta.guest : ''
  const isCheckoutPage = pageType === '/cart'
  const shouldHidePromoBar =
    !currentPromo ||
    !currentPromo?.tiers ||
    !currentPromo?.showToNonAuthenticatedUser === '1' ||
    (currentPromo?.showToNonAuthenticatedUser === '0' && userRole === 'guest')

  const isPromoCta = useMemo(() => !currentPromo || !currentPromo?.tiers, [currentPromo])
  const isPromoCampaign = useMemo(
    () =>
      (currentPromo?.showToNonAuthenticatedUser === '0' && userRole !== 'guest') ||
      currentPromo?.showToNonAuthenticatedUser === '1',
    [currentPromo, userRole]
  )

  useEffect(() => {
    // The height of promo bar changes according to it's type, the CSS variable helps to make it easier to adjust lot's of different layouts that depends on this height value
    document.documentElement.style.setProperty('--promo-bar-height', '40px')
  }, [])

  return (
    <PromoWrapper // TODO: fix nested ternary
      {...(isCheckoutPage
        ? shouldHidePromoBar
          ? {
              'data-promo-hide': 'active',
            }
          : {}
        : {})} // data-promo-hide={isCheckoutPage && !shouldHidePromoBar}
      id='saatchi_promo_nano_bar'
    >
      {isPromoCta ? (
        <>{!isCheckoutPage ? <PromoCta currentCTA={currentCTA} /> : ''}</>
      ) : (
        <>
          {isPromoCampaign ? (
            <PromoCampaign
              addMessage={addMessage}
              currentPromo={currentPromo}
              localeCountryCode={localeCountryCode}
            />
          ) : (
            <>{!isCheckoutPage ? <PromoCta currentCTA={currentCTA} /> : ''}</>
          )}
        </>
      )}
    </PromoWrapper>
  )
}

export default PromoBarConnector(FlashMessagesConnector(Component))
