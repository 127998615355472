import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'

const StoreLocaleAlert = dynamic(
  import(
    /* webpackChunkName: "preferences" */
    'components/Preferences/Alert/StoreLocaleAlert'
  ),
  noSSR
)

export default StoreLocaleAlert
