import React, { FC, useEffect, useState } from 'react'
import { updateMyUserPreferences } from 'apis/private/userPreferences'
import useFormData from 'hooks/formData'
import CountryInput from './CountryInput'
import CurrencyInput from './CurrencyInput'
import MeasurementInput from './MeasurementInput'
import { setUserPreferencesCookie } from 'apis/cookies'
import { preferenceForm, preferencesRules } from '../helpers'
import styles from '../Modal/Preferences.module.scss'
import { Locale } from 'types/Shared/Locale'

type PreferencesFormProps = {
  availableCountries: Locale['availableCountries']
  currenciesDropdownData: Record<string, any>
  onSubmit: (...args: Array<any>) => any
  requiresConfirmation: boolean
  userPreferences: Record<string, any>
} & Record<string, any>

const PreferencesForm: FC<PreferencesFormProps> = (props) => {
  const {
    availableCountries,
    currenciesDropdownData,
    isAuthenticated,
    onSubmit,
    requiresConfirmation,
    userPreferences,
  } = props
  const [state, handleState] = useState({
    isBlocked: false,
  })
  const { isBlocked } = state
  const { formData, onChangeInput, validateFormData } = useFormData({
    initialState: { ...preferenceForm, ...userPreferences },
    formRules: preferencesRules,
  })
  const { countryCode, currency, measurementUnit } = formData

  useEffect(() => {
    window.dataLayer.push({
      event: 'user preferences start',
    })
  }, [])

  const handleSubmit = async (ev: React.SyntheticEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const validForm = await validateFormData()

    if (!validForm) {
      return
    }

    handleState({ ...state, isBlocked: true })

    // If authenticated write USER preferences - Gallery Endpoint
    if (isAuthenticated) {
      await updateMyUserPreferences({
        countryCode,
        currency,
        measurementUnit,
      })
    }

    const { payload, messages, success } = await setUserPreferencesCookie({
      countryCode,
      currency,
      measurementUnit,
    })

    if (!success) {
      handleState({
        ...state,
        isBlocked: false,
        error: (messages || []).join(','), // Array[ 'error message' ]
      })
      window.dataLayer.push({
        event: 'user preferences error',
      })
      return
    }

    window.dataLayer.push({
      event: 'user preferences success',
    })
    onSubmit(payload)
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      {isBlocked && <div className={styles.loader} />}

      <p className={`${styles.title} defaultTypographyH3`}>Choose Your Preferences</p>

      {requiresConfirmation ? (
        <p className={`${styles.errorTitle}`}>Please confirm your store country</p>
      ) : null}

      <fieldset className='defaultFieldset'>
        <CountryInput data={availableCountries} onChange={onChangeInput} selected={countryCode} />

        <CurrencyInput data={currenciesDropdownData} onChange={onChangeInput} selected={currency} />

        <MeasurementInput onChange={onChangeInput} selected={measurementUnit} />

        <button type='submit' data-action='log-in' className='defaultButtonGunMetal'>
          <p className='defaultTypographyCtaLarge defaultTextUppercase'>Update Preferences</p>
        </button>
      </fieldset>
    </form>
  )
}

export default PreferencesForm
