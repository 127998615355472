import { ActionTypes as Action, Constants } from 'actions/user/cart'
import { Cart } from 'types/User/Cart'

export const defaultState = {
  count: 0,
  details: {},
  quickView: {
    show: false,
    productSku: null,
  },
}

type State = Cart

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_CART:
      return { ...state, count: action.payload || 0 }

    case Constants.SHOW_CART:
      return {
        ...state,
        quickView: {
          show: action.payload,
          productSku: null,
        },
      }

    case Constants.SHOW_CART_ITEM:
      return {
        ...state,
        quickView: {
          show: true,
          productSku: action.payload,
        },
      }

    default:
      return state
  }
}
