import React, { FC } from 'react'
import useScript from 'hooks/loadScript'
import FacebookIcon from 'svg/facebook-2021.svg'
import { fetchCheckFacebookConnect } from 'apis/authentication'
import styles from '../Modals/Auth.module.scss'
import GeoLocationConnector, { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'

declare let FB

// Wrap facebook login call in promise for use within saga.
const fbLoginPromise = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    FB.login(
      (response) => {
        if (response.status === 'connected') {
          resolve(response)
        } else {
          reject(response)
        }
      },
      {
        scope: 'email',
      }
    )
  })
}

// Wrapper to HANDLE Promise function
const fbLogin = async () => {
  try {
    const fbResponse: Record<string, any> = await fbLoginPromise()

    if (fbResponse.status === 'connected') {
      const { authResponse } = fbResponse
      // authResponse: { accessToken, data_access_expiration_time, expiresIn, graphDomain, signedRequest, userID }
      return {
        payload: { ...authResponse },
        success: true,
      }
    }

    // Handle UNKNOWN case
    return {
      payload: fbResponse,
      success: true,
    }
  } catch (error) {
    return {
      payload: {},
      success: false,
    }
  }
}

const SCRIPT = `
  window.fbAsyncInit = function() {
    FB.init({
      appId: document.querySelector('[name="fb:app_id"]').content,
      xfbml: false,
      cookie: true,
      version: 'v3.2'
    });
    FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
`
type Props = {
  handleFbSession: (...args: Array<any>) => any
  // Pass back session info on SUCCESS
  handleIsBlocking: (...args: Array<any>) => any // Block LOGIN Form
} & GeoLocationProps

const Component: FC<Props> = ({ handleFbSession, handleIsBlocking, geoLocation }) => {
  const { countryCode } = geoLocation
  useScript({
    name: 'facebook-login',
    text: SCRIPT,
  })
  return (
    <button
      type='button'
      className={styles.faceBookLogin}
      onClick={async () => {
        handleIsBlocking(true)
        window.dataLayer.push({
          event: 'facebook authentication start',
        })

        const fbResponse = await fbLogin()

        if (!fbResponse.success) {
          handleIsBlocking(false)
          window.dataLayer.push({
            event: 'facebook authentication error',
          })
          return
        }

        const session = await fetchCheckFacebookConnect(countryCode)
        handleFbSession(session)
        window.dataLayer.push({
          event: 'facebook authentication success',
        })
      }}
    >
      <FacebookIcon />
      <p className='defaultTypographyMedium defaultTextBold'>Log in With Facebook</p>
    </button>
  )
}

export default GeoLocationConnector(Component)
