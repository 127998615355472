import React, { FC } from 'react'
import styles from './Header.module.scss'

// https://www.a11y-collective.com/blog/skip-to-main-content/
// Skip Header and Navigation - Show me page content
type SkipToContentProps = {
  skipTo?: string
}

const TITLE = 'Skip to Main Content'
const SKIP_TO = 'nav + div' // We need to select first DIV after NAV or create custom prop
const SKIP_ID = 'skip-to-content'

const SkipToContent: FC<SkipToContentProps> = ({ skipTo }) => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault()

    const container: HTMLElement | null = document.querySelector(skipTo || SKIP_TO)

    if (container) {
      if (!container.hasAttribute('id')) {
        container.setAttribute('id', SKIP_ID)
      }

      window.location.href = `#${SKIP_ID}`
    }
  }

  return (
    <a className={styles.skipToContent} onClick={onClick} href={`#${SKIP_ID}`}>
      {TITLE}
    </a>
  )
}

export default SkipToContent
