import React, { FC, useEffect, useState } from 'react'
import { postLogin } from 'apis/authentication'
import { setUserPreferencesCookie } from 'apis/cookies'
import SALink from 'components/SALink'
import InputLabel from 'components/SA/FormElements/InputLabel'
import Recaptcha, { getCaptchaToken } from 'components/SA/FormElements/Recaptcha'
import useFormData from 'hooks/formData'
import { getQueryObject } from 'lib/url/parse'
import PasswordInput from './PasswordInput'
import FacebookLogin from './FacebookLogin'
import { loginForm, loginRules } from '../helpers'
import styles from '../Modals/Auth.module.scss'
import classNames from 'classnames'
import GeoLocationConnector, { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'

type LoginProps = {
  message?: string
  onClickRegister: (...args: Array<any>) => any
  onClickResetPassword: (...args: Array<any>) => any
  onSubmit: (...args: Array<any>) => any
} & GeoLocationProps

const Login: FC<LoginProps> = (props) => {
  const { message, onClickRegister, onClickResetPassword, onSubmit, geoLocation } = props
  const { countryCode } = geoLocation
  const [state, handleState] = useState({
    error: '',
    isBlocked: false,
    isTVScreen: false,
  })
  const { error, isBlocked, isTVScreen } = state
  const { formData, formErrors, onChangeInput, validateFormData, updateFormErrors } = useFormData({
    initialState: loginForm,
    formRules: loginRules,
  })
  const { email, password } = formData
  const { email: emailError, password: passwordError } = formErrors
  useEffect(() => {
    window.dataLayer.push({
      event: 'login start',
    })

    if (window && window.location) {
      const query = getQueryObject(window.location.search)
      const { source } = query || {}
      handleState((prevProps) => ({ ...prevProps, isTVScreen: source === 'lgtv' }))
    }
  }, [])

  const handleSubmit = async (ev: React.SyntheticEvent<HTMLFormElement>): Promise<void> => {
    ev.preventDefault()
    const validForm = await validateFormData()

    if (!validForm) {
      return
    }

    handleState({
      ...state,
      isBlocked: true,
      error: '',
    })
    const captchaAction = 'login_submit'
    const captchaToken = await getCaptchaToken(captchaAction)
    const { messages, success, userEmail, userId, userType, firstName } = await postLogin(
      {
        captchaAction,
        captchaToken,
        email,
        password,
      },
      countryCode
    )

    if (!success) {
      handleState({
        ...state,
        isBlocked: false,
        error: (messages || []).join(','), // Array[ 'error message' ]
      })
      window.dataLayer.push({
        event: 'login error',
      })
      return
    }

    // Previously this dataLayer event happened before, but we need api data from `postLogin`
    window.dataLayer.push({
      event: 'login success',
      'user category': userType,
      'user email': userEmail,
      'user first name': firstName,
      'user id': userId,
    })
    // We need to check if USER is trying to login via LGTV
    const query = getQueryObject(window.location.search)
    const { auth_token: token, source, subscribe } = query || {}

    if (source === 'lgtv') {
      if (subscribe) {
        window.location.href = `/tv/subscription`
      } else {
        window.location.href = `/tv/pin/${token || ''}`
      }

      return
    }

    onSubmit()
  }

  return (
    <form noValidate onSubmit={handleSubmit} className={styles.loginForm} data-type='login-form'>
      {isBlocked && <div className={styles.loader} />}

      {message && <p className={`${styles.message} defaultTypographyXSmall`}>{message}</p>}

      <p className={`${styles.title} defaultTypographyH3`}>Welcome to Saatchi Art</p>

      <fieldset className='defaultFieldset'>
        <FacebookLogin
          handleIsBlocking={(blocking: boolean) => {
            handleState({ ...state, isBlocked: blocking })
          }}
          handleFbSession={() => {
            onSubmit()
          }}
        />

        <p className={`${styles.orText} defaultTypographyH6 defaultTextUppercase`}>or</p>

        <div className={styles.formInputContainer}>
          <InputLabel
            type='email'
            name='email'
            value={email}
            title='Email Address'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, email: '' })
            }}
            error={emailError}
            styleOverride={styles.authInputField}
          />

          <PasswordInput
            name='password'
            password={password}
            title='Password'
            onChange={(ev) => {
              onChangeInput(ev)
              updateFormErrors({ ...formErrors, password: '' })
            }}
            passwordError={passwordError}
            styleOverride={styles.authInputField}
          />

          <button
            type='button'
            className={`${styles.resetPassword} ${styles.link}`}
            onClick={onClickResetPassword}
          >
            <p className='defaultTypographyCtaMedium defaultTextUppercase'>Forgot password</p>
          </button>
        </div>

        <button
          type='submit'
          data-action='log-in'
          className={classNames(
            {
              [styles.buttonError]: passwordError || emailError,
            },
            'defaultButtonGunMetal'
          )}
        >
          <p className='defaultTypographyCtaLarge, defaultTextUppercase'>Log In</p>
        </button>

        {error && <p className={`${styles.error} defaultTypographyXSmall`}>{error}</p>}

        {isTVScreen && (
          <p className={styles.terms}>
            By logging in you agree to our amended{' '}
            <SALink
              href='https://www.saatchiart.com/terms'
              rel='noopener noreferrer'
              target='_blank'
            >
              Terms of Service
            </SALink>
            , new{' '}
            <SALink
              href='https://www.saatchiart.com/tv/terms'
              rel='noopener noreferrer'
              target='_blank'
            >
              Saatchi on TV Terms and Conditions of Use
            </SALink>{' '}
            and{' '}
            <SALink
              href='https://www.saatchiart.com/tv/privacy'
              rel='noopener noreferrer'
              target='_blank'
            >
              Saatchi on TV App Privacy Notice
            </SALink>
            .
          </p>
        )}

        <Recaptcha styleOverride={styles.loginRecaptcha} />
      </fieldset>
      <div className={styles.callout}>
        <span className='defaultTypographySmall'>Don't have an account?</span>
        <button type='button' className={styles.calloutButton} onClick={onClickRegister}>
          <p className='defaultTypographyCtaMedium defaultTextUppercase'>Register</p>
        </button>
      </div>
    </form>
  )
}

export default GeoLocationConnector(Login)
