import styled from 'styled-components'
import { space, fontSize, color, fontWeight } from 'styled-system'
export default styled.p`
  margin: 0;
  ${space};
  ${fontSize};
  ${color};
  ${fontWeight};
  ${(props) => props.italic && `font-style: italic`};
  ${(props) => props.bold && `font-weight: bold`};
  ${(props) => props.capitalize && `text-transform: capitalize`};
`