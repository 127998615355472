import easelAPI, {
  deleteOptions,
  getOptions,
  postOptions,
  EaselResponse,
  EaselPayloadResponse,
  putOptions,
} from 'apis/helpers/easelAPI'
import { setCartData } from 'components/CartUrgencyBar/helper'
import { updateCartClientStorage } from 'store/middleware/client'
import { CartResponse } from 'types/User/Cart'

export const fetchCart = async (): Promise<EaselPayloadResponse<CartResponse>> => {
  const options = getOptions
  const json = await easelAPI.fetchJSON({
    name: 'fetchCart',
    options,
    url: '/cart',
  })
  const { payload, success } = json
  if (success) {
    setCartData(payload) // Update Local Storage for Cart Urgency
  }

  if (window !== undefined) {
    const { itemsCartCount } = payload
    updateCartClientStorage({ count: itemsCartCount })
  }

  return json
}

export const fetchCartCount = async (): Promise<number> => {
  const options = getOptions
  const json = await easelAPI.fetchJSON({
    name: 'fetchCartCount',
    options,
    url: '/cart/count',
  })

  if (typeof json.payload === 'number') {
    return json.payload
  }

  return 0
}

export const postCartItem = async (sku: string): Promise<EaselResponse> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'postCartItem',
    options,
    url: '/cart/items',
  })
  return json
}

export const deleteCartItem = async (sku: string): Promise<EaselResponse> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'deleteCartItem',
    options,
    url: '/cart/items',
  })
  return json
}

export const updateCartItemQuantity = async (payload: {
  sku: string
  quantity: number
}): Promise<EaselResponse> => {
  const { sku, quantity } = payload
  const options = {
    ...putOptions,
    body: JSON.stringify({
      quantity,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'updateCartItemQuantity',
    options,
    url: `/cart/items/${sku}`,
  })
  return json
}

export const saveForLaterCartItem = async (sku: string): Promise<EaselResponse> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      sku,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'saveForLaterCartItem',
    options,
    url: '/cart/items/save-for-later',
  })
  return json
}

export const putGiftCardCode = async (giftCard: string): Promise<EaselResponse> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      giftCard,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'putGiftCard',
    options,
    url: '/cart/gift-card',
  })
  return json
}

export const deleteGiftCardCode = async (giftCard: string): Promise<EaselResponse> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      giftCard,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'deleteGiftCardCode',
    options,
    url: '/cart/gift-card',
  })
  return json
}

export const putCouponCode = async (couponCode: string): Promise<EaselResponse> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      couponCode,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'putCouponCode',
    options,
    url: '/cart/coupon',
  })
  return json
}

export const deleteCouponCode = async (couponCode: string): Promise<EaselResponse> => {
  const options = {
    ...deleteOptions,
    body: JSON.stringify({
      couponCode,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'deleteCouponCode',
    options,
    url: '/cart/coupon',
  })
  return json
}

export const updateShippingAddress = async (addressBookItemId: string): Promise<EaselResponse> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      addressBookItemId,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'updateShippingAddress',
    options,
    url: '/cart/shipping',
  })
  return json
}

export const updatePaymentMethod = async (paymentMethodId: string): Promise<EaselResponse> => {
  const options = {
    ...putOptions,
    body: JSON.stringify({
      paymentMethodId,
    }),
  }
  const json = await easelAPI.fetchJSON({
    name: 'updatePaymentMethod',
    options,
    url: '/cart/payment',
  })
  return json
}

export const postCartOrder = async (payload: Record<string, any>): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify(payload) }
  const json = await easelAPI.fetchJSON({
    name: 'postCartOrder',
    options,
    url: '/cart/order',
  })
  return json
}

export const postExpressCheckoutSummary = async (
  payload: Record<string, any>
): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify(payload) }
  const json = await easelAPI.fetchJSON({
    name: 'postExpressCheckoutSummary',
    options,
    url: '/cart/express-checkout-summary',
  })
  return {
    ...json.payload,
  }
}
