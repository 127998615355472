import { createSelector } from 'reselect'
import { Shared } from 'types/Shared'
import { getShared } from './helpers'

export const getModal = createSelector(
  [getShared],
  (shared: Shared): Record<string, any> => shared.modal || {}
)

export const getModalIsVisible = createSelector(
  [getModal],
  (modal: Record<string, any>): boolean => modal.isVisible || false
)

export const getModalName = createSelector(
  [getModal],
  (modal: Record<string, any>): string => modal.name || ''
)

export const getHasModalName = createSelector([getModal], ({ name }): boolean => !!name)

export const getModalData = createSelector(
  [getModal],
  ({ data }): Record<string, any> => data || {}
)

export const getIsCurrentModalAvailable = (name: string = '') =>
  createSelector([getModalName], (modalName): boolean => !!(modalName && modalName === name))
