import React, { FC } from 'react'
import styles from '../Modal/Preferences.module.scss'
import CaretDownIcon from 'svg/caretDown.svg'

type MeasurementInputProps = {
  selected: string
  onChange: (ev: React.SyntheticEvent<HTMLElement>) => void
}
const NAME = 'measurementUnit'
const DATA = {
  in: 'Inches/Pounds',
  cm: 'Centimeters/Kilograms',
}

const MeasurementInput: FC<MeasurementInputProps> = (props) => {
  const { onChange, selected } = props
  const dataArray = Object.entries(DATA) || []
  return (
    <div className={styles.preferencesInputBox}>
      <label htmlFor={NAME} className={styles.preferencesInputLabel}>
        <span>Measurement*</span>
        <div className={styles.preferencesInputField}>
          <input type='text' value={DATA[selected]} disabled />
          <CaretDownIcon />
        </div>
      </label>

      <select
        className='defaultInputSelectField'
        id={NAME}
        name={NAME}
        onChange={onChange}
        value={selected}
        tabIndex={0}
      >
        {dataArray.map((item) => {
          const [key, name] = item
          return (
            <option key={key} value={key}>
              {String(name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default MeasurementInput
