/*
  Global Modals
*/
import React, { FC } from 'react'

import useOpenModalByQueryParams from './useOpenModalByQueryParams'
import AuthenticationModal from 'components/Authentication/Modals'
import PreferencesModal from 'components/Preferences/Modal'
import StoreLocaleAlert from 'components/Preferences/Alert'

const SharedLayout: FC = () => {
  useOpenModalByQueryParams()
  return (
    <>
      <StoreLocaleAlert />

      <PreferencesModal />

      <AuthenticationModal />
    </>
  )
}

export default SharedLayout
