import React, { FC } from 'react'
import styles from '../Modal/Preferences.module.scss'
import CaretDownIcon from 'svg/caretDown.svg'

type CurrencyInputProps = {
  data: Record<string, any>
  selected: string
  onChange: (ev: React.SyntheticEvent<HTMLElement>) => void
}
const NAME = 'currency'

const CurrencyInput: FC<CurrencyInputProps> = (props) => {
  const { data, onChange, selected } = props
  const dataArray = Object.entries(data) || []
  return (
    <div className={styles.preferencesInputBox}>
      <label htmlFor={NAME} className={styles.preferencesInputLabel}>
        <span>Currency*</span>
        <div className={styles.preferencesInputField}>
          <input type='text' value={data[selected]} disabled />
          <CaretDownIcon />
        </div>
      </label>

      <select
        className='defaultInputSelectField'
        name={NAME}
        id={NAME}
        value={selected}
        onChange={onChange}
        tabIndex={0}
      >
        {dataArray.map((item) => {
          const [key, name] = item
          return (
            <option key={key} value={key}>
              {String(name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default CurrencyInput
