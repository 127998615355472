import styled from 'styled-components'
import { space, fontSize, color, fontWeight, width } from 'styled-system'

const BASE = (props) => `
  font-weight: normal;
  margin: 0;
  ${space};
  ${fontSize};
  ${fontWeight};
  ${color};
  ${width};
  ${props.italic && 'font-style: italic'};
`

export const Serif = styled.div`
  ${(props) => BASE(props)};
`
export const H2 = styled('h2')`
  ${(props) => BASE(props)};
`
H2.defaultProps = {
  fontSize: 5,
}

export default Serif
