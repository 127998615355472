import React, { FC, PropsWithChildren, useEffect, useRef } from 'react'
import styles from './ModalV2.module.scss'
import Portal from './Portal'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'
import Animation from 'components/Animations/Animation'
import CloseIcon from 'svg/close-x.svg'
import classNames from 'classnames'

const animationDuration = 150
const animation = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}
const defaultAnimationStyles = {
  position: 'fixed',
  top: '0',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'transform, opacity',
  zIndex: '1000',
  overflow: 'hidden',
}

type ModalComponentProps = ModalProps & {
  layout?: string
  styleOverride?: string
  decayTime?: number
  fullTakeover?: boolean
  isCloseButtonVisible?: boolean
  closeButtonStyles?: string
  onCloseModal?: () => void
}
const ModalComponent: FC<PropsWithChildren<ModalComponentProps>> = (props) => {
  const {
    children,
    closeButtonStyles = '',
    closeModal,
    fullTakeover,
    isCloseButtonVisible,
    isModalAvailable,
    isVisible,
    layout = 'standard',
    onCloseModal,
    styleOverride,
  } = props
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleCloseModal = () => {
    onCloseModal?.()
    closeModal()
  }

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [buttonRef.current])

  // We check Redux state to see if NAME matches currrent modal
  if (!isModalAvailable) {
    return null
  }

  return (
    <Portal closeModal={handleCloseModal}>
      <Animation
        defaultStyles={defaultAnimationStyles}
        duration={animationDuration}
        isVisible={isVisible}
        {...animation}
      >
        <div className={`${styles.modal} ${styleOverride}`} data-type='modal'>
          {layout === 'standard' && (
            <div className={styles.modalBackground} onClick={handleCloseModal} />
          )}
          <div
            className={classNames(
              {
                [styles.modalContentFullTakeover]: fullTakeover,
              },
              styles.modalContent
            )}
            data-type='modal-content'
          >
            <aside role='dialog' aria-modal='true' tabIndex={-1}>
              {isCloseButtonVisible && (
                <button
                  ref={buttonRef}
                  className={classNames('defaultSvgButton', styles.closeButton, {
                    [closeButtonStyles]: closeButtonStyles !== '',
                  })}
                  onClick={handleCloseModal}
                  tabIndex={0}
                >
                  <CloseIcon />
                </button>
              )}
              {children}
            </aside>
          </div>
        </div>
      </Animation>
    </Portal>
  )
}
export default ModalConnector(ModalComponent)
