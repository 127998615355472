import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import * as AccountStyles from 'styles/account'
const { Column } = AccountStyles
export const AppLayout = styled(Column)`
  position: relative;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  min-height: 400px;

  &[data-app='artdetail'] {
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
    @media (max-width: ${(props) => props.theme.maxWidth}) {
      padding: 0 15px;
    }
    @media (max-width: 1024px) {
      max-width: 770px;
    }
    [data-section='carousel'] {
      height: auto;
    }
    [data-section='artist-carousel'] {
      margin-top: 72px;
      height: auto;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        margin-top: 30px;
      }
      [data-type='artist-carousel-title'] {
        margin-bottom: 32px;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          margin-bottom: 16px;
        }
      }
    }

    [data-section='reference-line'] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1px;
      background: none;
      width: 100%;
      order: 3;
    }
    [data-section='rec-carousel'] {
      margin-top: 72px;
      height: auto;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        margin-top: 30px;
      }

      &[data-style='change-order'] {
        order: 5;
      }

      [data-type='rec-carousel-title'] {
        margin-bottom: 32px;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          margin-bottom: 16px;
        }
      }
    }
    [data-section='visii-carousel'] {
      margin-top: 72px;
      height: auto;
      order: 4;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        margin-top: 40px;
      }
      [data-type='visii-carousel-title'] {
        margin-bottom: 32px;
        @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
          margin-bottom: 16px;
        }
      }
    }
  }

  &[data-app='curatorNotes'] {
    width: 100%;
    min-height: 400px;
    max-width: 1256px;
    flex: 1 1 0%;
    margin: 0px auto;
    @media (max-width: ${(props) => props.theme.maxWidth}) {
      padding: 0 15px;
    }
    @media (max-width: 1024px) {
      max-width: 770px;
    }
  }

  &[data-app='account'] {
    width: 100%;
    min-height: 400px;
    max-width: 1256px;
    flex: 1 1 0%;
    margin: 0px auto;
    @media (max-width: 1024px) {
      max-width: 770px;
    }
  }

  &[data-app='account-artworks'] {
    [data-section='email-verification-section'] {
      padding: 40px 30px;
      margin: 0 30px;
      flex: 1;
      display: flex;
      flex-direction: column;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        padding: 24px 15px 0;
        margin: 0 15px;
      }
      a {
        color: #dc3a0f;
      }
      [data-type='title'] {
        margin-bottom: 24px;
      }
    }

    [id='recaptcha'] {
      margin-top: 50px;
      p,
      a {
        font-size: 12px;
      }
      a {
        text-transform: initial;
        padding: 0 2px;
      }
    }
  }

  &[data-app='curation'] {
    [data-type='top'] {
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
`
