import { $Shape } from 'utility-types'
import { Locale, LocaleCurrency, LocaleDataFromStoreLocale } from 'types/Shared/Locale'
// CONSTANTS
export const Constants = {
  CHANGE_LOCALE: 'CHANGE_LOCALE',
  FETCH_LOCALES: 'FETCH_ALL_LOCALES',
  GET_LOCALE_PREFERENCE: 'GET_LOCALE_PREFERENCE_COOKIE',
  SET_LOCALE_IN_DATALAYER: 'SETUP_SET_LOCALE_IN_DATALAYER',
  SET_CURRENCY: 'SET_LOCALE_CURRENCY',
  UPDATE_LOCALE: 'UPDATE_LOCALE_STORE',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CHANGE_LOCALE
      payload: string
    }
  | {
      type: typeof Constants.FETCH_LOCALES
    }
  | {
      type: typeof Constants.GET_LOCALE_PREFERENCE
    }
  | {
      type: typeof Constants.SET_LOCALE_IN_DATALAYER
    }
  | {
      type: typeof Constants.SET_CURRENCY
      payload: LocaleCurrency
    }
  | {
      type: typeof Constants.UPDATE_LOCALE
      payload: $Shape<Locale>
    }

// ACTION CALLS
export const Actions = {
  CHANGE_LOCALE: (payload: string): ActionTypes => ({
    type: Constants.CHANGE_LOCALE,
    payload,
  }),
  FETCH_LOCALES: (): ActionTypes => ({
    type: Constants.FETCH_LOCALES,
  }),
  GET_LOCALE_PREFERENCE: (): ActionTypes => ({
    type: Constants.GET_LOCALE_PREFERENCE,
  }),
  SET_LOCALE_IN_DATALAYER: (): ActionTypes => ({
    type: Constants.SET_LOCALE_IN_DATALAYER,
  }),
  SET_CURRENCY: (payload: LocaleCurrency): ActionTypes => ({
    type: Constants.SET_CURRENCY,
    payload,
  }),
  UPDATE_LOCALE: (payload: $Shape<Locale>): ActionTypes => ({
    type: Constants.UPDATE_LOCALE,
    payload,
  }),
}
