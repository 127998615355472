import easelAPI, { postOptions, EaselResponse } from 'apis/helpers/easelAPI'

type Payload = {
  name: string
  url: string
  value: string
}

// Limit Cookies USER can create by making specific URLs for a cookie, VALUE is only thing we will set
const cookieApi = async (payload: Payload): Promise<EaselResponse> => {
  const { name, url, value } = payload
  const options = {
    ...postOptions,
    body: JSON.stringify({ value }),
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const json = await easelAPI.fetchJSON({
    name,
    options,
    url,
  })
  return json
}

// To Deal with Akamai/Varnish we need to pass in cookies via EASEL API to write to Document
export const setLocaleCookie = async (value: string): Promise<EaselResponse> => {
  const json = await cookieApi({
    name: 'setLocaleCookie',
    url: '/cookie/locale',
    value,
  })
  return json
}

export type SetUserPreferencesCookie = {
  countryCode?: string
  currency?: string
  measurementUnit?: string
  override?: boolean
  neverConfirmed?: boolean
}

// To Deal with Akamai/Varnish we need to pass in cookies via EASEL API to write to Document
export const setUserPreferencesCookie = async (
  data: SetUserPreferencesCookie = {}
): Promise<EaselResponse> => {
  const options = { ...postOptions, body: JSON.stringify(data) }
  const json = await easelAPI.fetchJSON({
    name: 'setUserPreferencesCookie',
    options,
    url: '/cookie/user-preferences',
  })
  return json
}
