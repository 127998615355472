import easelAPI, { getOptions, EaselResponse } from 'apis/helpers/easelAPI'

export const fetchClientData = async (): Promise<EaselResponse> => {
  const options = getOptions
  const json = await easelAPI.fetchJSON({
    name: 'fetchClientData',
    options,
    url: '/client-data',
  })
  return json
}

export const fetchAllClientData = async (): Promise<EaselResponse> => {
  const options = getOptions
  const json = await easelAPI.fetchJSON({
    name: 'fetchAllClientData',
    options,
    url: '/all-client-data',
  })
  return json
}
