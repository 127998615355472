import { ActionTypes as Action, Constants } from 'actions/shared/modal'
import { Modal } from 'types/Shared/Modal'

export const defaultState = {
  isVisible: false,
  name: '', // NAME of component/modal
  data: {},
}

type State = Modal

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.CLOSE_MODAL:
      return { ...state, isVisible: false, name: '', data: {} }

    case Constants.OPEN_MODAL:
      return { ...state, isVisible: true, name: action.payload }

    case Constants.OPEN_MODAL_WITH_DATA: {
      const { name, data } = action.payload
      return { ...state, isVisible: true, name: name, data: data }
    }

    case Constants.RESET_MODAL:
      return { ...state, ...defaultState }

    default:
      return state
  }
}
