import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as ClientActions } from 'actions/shared/client'
import { isAuthenticated } from 'selectors/user/session'
import {
  getUserPreferences,
  getUserPreferencesConfirmedCountryCode,
  getUserPreferencesConfirmedCountryName,
  getUserPreferencesCountryCode,
  getUserPreferencesCountryName,
  getUserPreferencesCurrency,
  getUserPreferencesLocaleConfirmed,
  getUserPreferencesMeasurementUnit,
  getUserPreferencesSelectedCurrency,
} from 'selectors/user/cookie'
import {
  getAvailableCountries,
  getAvailableCurrencies,
  getCurrenciesDropdown,
  getLocaleIsReady,
} from 'selectors/shared/locale'
import { Locale, LocaleCurrency } from 'types/Shared/Locale'
import { UserPreferences } from 'types/User/Cookie'

const mapStateToProps = createStructuredSelector({
  availableCountries: getAvailableCountries,
  availableCurrencies: getAvailableCurrencies,
  currenciesDropdownData: getCurrenciesDropdown,
  isAuthenticated,
  localeIsReady: getLocaleIsReady,
  selectedCurrency: getUserPreferencesSelectedCurrency,
  userPreferences: getUserPreferences,
  userPreferencesConfirmed: getUserPreferencesLocaleConfirmed,
  userPreferencesConfirmedCountryCode: getUserPreferencesConfirmedCountryCode,
  userPreferencesConfirmedCountryName: getUserPreferencesConfirmedCountryName,
  userPreferencesCountryCode: getUserPreferencesCountryCode,
  userPreferencesCountryName: getUserPreferencesCountryName,
  userPreferencesCurrency: getUserPreferencesCurrency,
  userPreferencesMeasurementUnit: getUserPreferencesMeasurementUnit,
})

const mapDispatchToProps = (dispatch) => ({
  updateClientData: (clientData: Record<string, any>) =>
    dispatch([ClientActions.NEW_CLIENT_COMPLETE(clientData)]),
})

export type ConnectorProps = {
  availableCountries: Locale['availableCountries']
  availableCurrencies: Record<string, LocaleCurrency>
  currenciesDropdownData: Record<string, any>
  isAuthenticated: boolean
  localeIsReady: boolean
  selectedCurrency: Record<string, any>
  updateClientData: (clientData: Record<string, any>) => void
  userPreferences: UserPreferences
  userPreferencesConfirmed: boolean
  userPreferencesConfirmedCountryCode: string
  userPreferencesConfirmedCountryName: string
  userPreferencesCountryCode: string
  userPreferencesCountryName: string
  userPreferencesCurrency: UserPreferences['currency']
  userPreferencesMeasurementUnit: UserPreferences['measurementUnit']
}
export default connect(mapStateToProps, mapDispatchToProps)
