import fetch from 'isomorphic-fetch'
import { APIResponse, parseJSON } from './helpers'

export { createFormData } from './helpers'

export type LegacyResponse = APIResponse

export type LegacyPayloadResponse<T> = LegacyResponse & {
  payload: T
}

// TODO - Domain seems to break NAMESPACES, can we add CORS and hit DEVELOP
// const { SITE_URL } = process.env
// const LEGACY_API = `${SITE_URL || ''}`
// const DOMAIN = LEGACY_API
const DOMAIN = ''

type Payload = {
  defaultResponse?: Record<string, any> // If parseJSON fails => default response
  domain?: string // Override ENV's
  url: string // Override the default logic | custom url
  name: string // endpoint
  formOptions?: Record<string, any> // all FETCH options => METHOD, BODY, HEADERS etc...
}

const LEGACY_OPTIONS = {
  credentials: 'include',
  method: 'GET',
  headers: {
    'X-Requested-With': 'XMLHttpRequest', // For legacy isXmlHttpRequest check
  },
}

const fetchResponse = async (payload: Payload): Promise<APIResponse> => {
  try {
    const { domain, formOptions, url } = payload
    const domainUrl = domain || DOMAIN
    const response = await fetch(`${domainUrl}${url}`, {
      ...LEGACY_OPTIONS,
      ...formOptions,
    })
    return response
  } catch (error) {
    console.error('legacyAPI.fetchResponse failure:', { error })
    // Output e.g.: "Fetch Error: 404, Not found"
    return {
      headers: new Headers(),
      ok: false,
      status: 404,
    }
  }
}

const parseResponseToJSON = async (
  response: Response,
  name: string,
  defaultResponse?: Record<string, any>
): Promise<LegacyResponse> => {
  try {
    const json = await parseJSON(response, name, defaultResponse)
    return json
  } catch (error) {
    console.error('legacyAPI.parseResponseToJSON failure: ', { error })
    return {
      message: 'legacyAPI parseResponseToJSON Failure',
      success: false,
    }
  }
}

const fetchJSON = async (payload: Payload): Promise<LegacyResponse> => {
  try {
    const { name, defaultResponse } = payload
    const response = await fetchResponse(payload)
    const json = await parseResponseToJSON(response, name, defaultResponse)
    return json
  } catch (error) {
    console.error('legacyAPI.fetchJSON failure: ', { error })
    return {
      message: 'legacyAPI fetchJSON Failure',
      success: false,
    }
  }
}

const legacyAPI = {
  fetchResponse,
  fetchJSON,
  parseResponseToJSON,
}

export default legacyAPI
