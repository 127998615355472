/*
  Setup Specific logic for opening Modals on Initial Load of a PAGE base on a QUERY Param
*/
import { useEffect } from 'react'
import { MODAL_NAME as PreferencesModal } from 'components/Preferences/Modal/Preferences'
import { MODAL_NAME as LoginModal } from 'components/Authentication/Modals/Login'
import debounce from 'lib/debounce'
import { getQueryObject } from 'lib/url/parse'

import useModal from 'hooks/useModal'

const modalDebounce = debounce()
const TIMEOUT = 2000

const useOpenModalByQueryParams = () => {
  const { showModal } = useModal()

  const openModal = (): void => {}

  useEffect(() => {
    if (window.location) {
      const { search } = window.location
      const queryObject = getQueryObject(search)

      if (queryObject['show'] === 'preferences') {
        modalDebounce(() => showModal(PreferencesModal), TIMEOUT) // Open Modal in 2 Seconds
        return
      }
      if (queryObject['show'] === 'login') {
        modalDebounce(() => showModal(LoginModal), TIMEOUT) // Open Modal in 2 Seconds
        return
      }
    }
    modalDebounce(() => null) // Clear Initial Modal Call if no MATCHES
  }, [])
}

export default useOpenModalByQueryParams
