import fetch from 'isomorphic-fetch'
import { getBrowserStoreLocale } from 'lib/storeLocale/client'
import {
  checkAuthenticatedUserHeaders,
  createFormData,
  getOptions,
  multipartFormDataOptions,
  parseJSON,
  validateLoginStatus,
} from './helpers'

export { deleteOptions, getOptions, postOptions, putOptions } from './helpers'

interface EaselBaseResponse {
  errorCodes?: string[]
  errorMessages?: string[]
  isAuthenticated?: boolean
  loginRequired?: boolean
  message?: string
  payload?: Record<string, any>
  success?: boolean
  text?: string
}

export type EaselResponse = EaselBaseResponse | any

export type EaselValidateLoginResponse = EaselBaseResponse & {
  loginRequired: boolean
}

export type EaselPayloadResponse<T> = (EaselBaseResponse | EaselValidateLoginResponse) & {
  payload: T | null
}

const { APP_ENV, SITE_URL } = process.env
const isDev = APP_ENV === 'local'
// const LOCAL_API = `${SITE_URL || ''}/easel_api` // 'https://local.www.saatchiart.com/easel_api'
// const CLIENT_API = `/easel_api` // 'https://client.saatchiart.com'
// const DOMAIN = isDev ? LOCAL_API : CLIENT_API
const DOMAIN = `${SITE_URL || ''}/easel_api`

type ResponsePayload = {
  domain?: string
  url: string
  options: Record<string, any>
}

type JSONPayload = ResponsePayload & {
  name: string
}

/*
-- How to CONVERT existing API --

```
// To Deal with Akamai we need to fetch SESSION from CLIENTSIDE
export const fetchSession = async (): Promise<EaselResponse> => {
  const response = await fetch(`/easel_api/session`, getOptions)
  const json = await parseJSON(response, 'fetchSession')
  return json
}
```

```
export const fetchSession = async (): Promise<EaselResponse> => {
  const json =  await easelAPI.fetchJSON({ name:'fetchSession', url:'/session' })
  return json
}
```

-- How to CREATE --

```
const { APP_ENV, SITE_URL } = process.env
const DOMAIN = SITE_URL || 'https://www.saatchiart.com/'

// We need to use LEGACY endpoints ( skip easel api LOGIC )
export const postLogin = async (payload: Object): Promise<Object> => {
  const options = {
    ...postOptions,
    body: JSON.stringify({
      remember: true,
      ...payload,
    }),
  }
  const json =  await easelAPI.fetchJSON({ domain: DOMAIN, name: 'postLogin', options, url: '/auth-api/login' })
  return json
}
```

*/
const fetchResponse = async (payload: ResponsePayload): Promise<Response> => {
  try {
    const { domain, options, url } = payload
    const domainUrl = domain || DOMAIN
    const eStoreLocale = getBrowserStoreLocale()
    // Merge All Headers & create a new Header for AKAMAI Cached Pages
    const headers = {
      ...(options && options.headers ? options.headers : {}),
      'X-Easel-Client-Store-Locale': eStoreLocale,
    }
    const response = await fetch(`${domainUrl}${url}`, {
      ...options,
      headers,
    })
    return response
  } catch (error) {
    console.error('easelAPI.fetchResponse failure:', { error })
    // Output e.g.: "Fetch Error: 404, Not found"
    // @ts-ignore
    return {
      headers: new Headers(),
      ok: false,
      status: 404,
    }
  }
}

type PostFormData = {
  url: string
  data: Record<string, any>
}
// We need to simplify as much as possible, so BAD Headers/Options get added to request
const postFormData = async ({ url, data }: PostFormData): Promise<Response> => {
  try {
    const domainUrl = DOMAIN
    const formData = createFormData(data)
    const response = await fetch(`${domainUrl}${url}`, {
      ...multipartFormDataOptions,
      body: formData,
    })
    return response
  } catch (error) {
    console.error('easelAPI.postFormData failure:', { error })
    // Output e.g.: "Fetch Error: 404, Not found"
    // @ts-ignore
    return {
      headers: new Headers(),
      ok: false,
      status: 404,
    }
  }
}

const parseResponseToJSON = async (response: Response, name: string): Promise<EaselResponse> => {
  try {
    const isAuthenticated = checkAuthenticatedUserHeaders(response)
    const json = await parseJSON(response, name)
    return {
      isAuthenticated,
      ...json,
    } // { isAuthenticated: boolean, payload: Object, success: boolean}
  } catch (error) {
    console.error('easelAPI.parseResponseToJSON failure: ', { error })
    return {
      isAuthenticated: false,
      message: 'easelApi getJSON Failure',
      payload: {},
      success: false,
    }
  }
}

const parseResponseToValidateLoginJSON = async (
  response: Response,
  name: string
): Promise<EaselResponse> => {
  try {
    const json = await parseResponseToJSON(response, name)
    validateLoginStatus(json)
    return json
  } catch (error) {
    console.error('easelAP.parseResponseToValidateLoginJSON failure: ', { error })
    return {
      isAuthenticated: false,
      message: 'easelApi getJSON Failure',
      payload: {},
      success: false,
    }
  }
}

const fetchJSON = async (payload: JSONPayload): Promise<EaselResponse> => {
  try {
    const { name } = payload
    const response = await fetchResponse(payload)
    const json = await parseResponseToJSON(response, name)
    return json
  } catch (error) {
    console.error('easelAP.fetchJSON failure: ', { error })
    return {
      isAuthenticated: false,
      message: 'easelApi getJSON Failure',
      payload: {},
      success: false,
    }
  }
}

const fetchValidateLoginJSON = async (
  payload: JSONPayload
): Promise<EaselValidateLoginResponse> => {
  try {
    const { name } = payload
    const response = await fetchResponse(payload)
    const json = await parseResponseToValidateLoginJSON(response, name)
    return json
  } catch (error) {
    console.error('easelAPI.fetchValidateLoginJSON failure: ', { error })
    return {
      isAuthenticated: false,
      loginRequired: false,
      message: 'easelApi getValidateLoginJSON Failure',
      payload: {},
      success: false,
    }
  }
}

const easelAPI = {
  fetchResponse,
  fetchJSON,
  fetchValidateLoginJSON,
  parseResponseToJSON,
  parseResponseToValidateLoginJSON,
  postFormData,
}

export default easelAPI
