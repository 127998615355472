import { ActionTypes as Action, Constants } from 'actions/shared/qa'

export const defaultState = {}
type State = { enabled?: true }

export default (state: State = defaultState, action: Action): State => {
  switch (action.type) {
    case Constants.SET_QA_ENABLED:
      return { enabled: true }
    default:
      return state
  }
}
