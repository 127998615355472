/*
 * This `error` is different from `_error`, which is part of NEXTjs Framework
 * This page is used for a specific HELPER on SSR to create an Error Page
 * We may want to move over `_error` logic to follow this logic **TBD**
 */
import React from 'react'
import withRedux from 'next-redux-wrapper'
import Head from 'next/head'
import Theme from 'components/Theme/Error'
import { PageActions } from 'actions'
import { getSiteContext } from 'selectors/siteContext'
import { App, Store } from 'apps/error'
import { storeSetup } from 'lib/pageSetup'

export function Error(
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> {
  const { siteContext } = props
  const title = `Error - ${siteContext === 'limited' ? 'Limited by ' : ''}Saatchi Art`
  return (
    <Theme>
      <Head>
        <title>{title}</title>
      </Head>

      <App {...props} />
    </Theme>
  )
}

Error.getInitialProps = async (props) => {
  const { store, req, res, query } = props

  // Clientside
  if (!req) {
    return {} // This page should never render clientside
  }

  // SSR
  storeSetup(store, req)
  const siteContext = getSiteContext(store.getState())
  // Pull out ERROR data - Passed in from Express Route
  const { page } = query
  store.dispatch(PageActions.SET(page))
  // We need to overwrite the statusCode. The default status code
  // in Express is 200, we need to set the status code else default to 404
  const { statusCode } = page?.data || {}
  res.statusCode = statusCode || 500
  return {
    siteContext,
    ...(page && page.data ? page.data : {}), // contains error `message` & `text`
  }
}

export default withRedux(Store)(Error)
