import { createSelector } from 'reselect'
import { Shared } from 'types/Shared'
import { FlashMessage, FlashMessages, FlashMessageType } from 'types/Shared/FlashMessage'
import { getShared } from './helpers'

export const getFlashMessage = createSelector(
  [getShared],
  (shared: Shared): FlashMessage => shared.flashMessage || {}
)

export const getMessages = createSelector(
  [getFlashMessage],
  (flashMessage: FlashMessage): FlashMessages => flashMessage.messages || []
)

export const hasMessages = createSelector(
  [getMessages],
  (messages: Array<any>): boolean => Boolean(messages && messages.length) || false
)

export const getType = createSelector(
  [getFlashMessage],
  (flashMessage: FlashMessage): FlashMessageType => flashMessage.type || 'error'
)
