import { HYDRATE } from 'next-redux-wrapper'
import { Constants as Client } from 'actions/shared/client'

/*
 * Higher-Order Reducer
 * next-redux-wrapper & HYDRATE Action
 * https://github.com/kirill-konshin/next-redux-wrapper#state-reconciliation-during-hydration
 * We need to have a GLOBAL Reducer/Event for STATE reconciliation during hydration
 *
 * > The payload of this action will contain the state at the moment of static generation or server side rendering,
 * so your reducer must merge it with existing client state properly.
 *
 * We accept one action HYDRATE everything else run through the APP reduce like NORMAL
 */
const hydrateHOC = (appReducer: (...args: Array<any>) => any): Record<string, any> => {
  return (state: Record<string, any> = {}, action: Record<string, any>): Record<string, any> => {
    switch (action.type) {
      case HYDRATE:
        return { ...state, ...action.payload }

      case Client.NEW_CLIENT_COMPLETE: {
        // For OPS or Apps that require session, we will already setup
        // So if no PAYLOAD we can updated just CLIENT
        if (!action.payload) {
          const newState = {
            ...state,
            shared: {
              ...state.shared,
              client: { setup: true },
            },
          }
          return newState
        }

        // Merge API data in
        const {
          abTest,
          cart,
          cookie,
          featureFlags,
          isMobileTraffic,
          locale,
          session,
        } = action.payload
        const newState = {
          ...state,
          shared: {
            ...state.shared,
            client: { setup: true },
            featureFlags,
            isMobileTraffic,
            locale: { ...state.shared.locale, ...locale },
          },
          user: { ...state.user, abTest, cart, cookie, session },
        }
        return newState
      }

      case 'INIT':
      default:
        return appReducer(state, action)
    }
  }
}

export default hydrateHOC
