import React from 'react'

import styled from 'styled-components'
import P from 'components/P'
export const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSizes[6]}px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[7]}px;
  }
`
export const Text = styled(P)`
  color: ${(props) => props.theme.colors.secondary};
  line-height: 1.42em;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
  }
  ${(props) => props.small && `font-size: 12px; opacity: 0.5;`}
`
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 375px;
  a {
    display: inline-block;
    color: ${(props) => props.theme.colors.secondary};
    text-decoration: underline;
  }
`
