import fetch from 'isomorphic-fetch'
import { APIResponse, getOptions, parseJSON } from './helpers'

export {
  deleteOptions,
  getErrorCodes,
  getOptions,
  parseJSON,
  patchOptions,
  postOptions,
  putOptions,
} from './helpers'

export type GalleryResponse = APIResponse

export type GalleryValidateLoginResponse = GalleryResponse & {
  loginRequired: boolean
}

export type GalleryPayloadResponse<T> = GalleryResponse & T

// TODO - Domain seems to break NAMESPACES, can we add CORS and hit DEVELOP
// const { SITE_URL } = process.env
// const LOCAL_API = `${SITE_URL || ''}/api`
const LOCAL_API = `/api`
const DOMAIN = LOCAL_API

type ResponsePayload = {
  domain?: string
  url: string
  options: Record<string, any>
}

type JSONPayload = ResponsePayload & {
  name: string
}

const fetchResponse = async (payload: ResponsePayload): Promise<APIResponse> => {
  try {
    const { domain, options, url } = payload
    const domainUrl = domain || DOMAIN
    const response = await fetch(`${domainUrl}${url}`, {
      ...getOptions,
      ...options,
    })
    return response
  } catch (error) {
    console.error('galleryAPI.fetchResponse failure:', { error })
    // Output e.g.: "Fetch Error: 404, Not found"
    return {
      headers: new Headers(),
      ok: false,
      status: 404,
    }
  }
}

const parseResponseToJSON = async (
  response: Response,
  name: string,
  defaultResponse?: Record<string, any>
): Promise<GalleryResponse> => {
  try {
    const json = await parseJSON(response, name, defaultResponse)
    return json
  } catch (error) {
    console.error('galleryAPI.parseResponseToJSON failure: ', { error })
    return {
      message: 'galleryAPI parseResponseToJSON Failure',
      success: false,
    }
  }
}

const fetchJSON = async (payload: JSONPayload): Promise<GalleryResponse> => {
  try {
    const { name } = payload
    const response = await fetchResponse(payload)
    const json = await parseResponseToJSON(response, name)
    return json
  } catch (error) {
    console.error('galleryAPI.fetchJSON failure: ', { error })
    return {
      message: 'galleryAPI fetchJSON Failure',
      success: false,
    }
  }
}

const galleryAPI = {
  fetchJSON,
  fetchResponse,
  parseResponseToJSON,
}

export default galleryAPI
