"use strict";
//@ts-nocheck
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.storeSetup = exports.pagePath = exports.disableEpics = exports.flags = exports.siteContext = exports.navigation = exports.qaEnabled = exports.location = exports.locale = void 0;
/*
 * We do NOT always want to fill out all DATA in Redux STORE
 * Akamai Cache will cache the Redux State, so we need to be SMART
 * do NOT store userInformation, cookies, abTest, etc...
 * we need to fetch USER specific data from clientside
 */
const page_1 = require("actions/page");
const shared_1 = require("actions/shared");
const siteContext_1 = require("actions/siteContext");
const parse_1 = __importStar(require("lib/url/parse"));
const locale = (store, req) => {
    const { localeAvailableCountries, localeAvailableCurrencies, localeSSR } = req;
    store.dispatch(shared_1.Actions.UPDATE_LOCALE({
        availableCountries: localeAvailableCountries, // Countries we ship to
        availableCurrencies: localeAvailableCurrencies, // Currency Dropdown | Metadata Info
        serverResponse: localeSSR,
    }));
};
exports.locale = locale;
const location = (store, req) => {
    store.dispatch(shared_1.Actions.SET_LOCATION((0, parse_1.getHref)(req)));
};
exports.location = location;
const qaEnabled = (store, req) => {
    const { qaEnabled } = req;
    if (qaEnabled) {
        store.dispatch(shared_1.Actions.SET_QA_ENABLED(qaEnabled));
    }
};
exports.qaEnabled = qaEnabled;
const navigation = (store, req) => {
    const { query } = req.query || {};
    store.dispatch(shared_1.Actions.NAVIGATION_UPDATE_SEARCH({
        type: 'art',
        value: query || '',
    }));
};
exports.navigation = navigation;
const siteContext = (store, req) => {
    store.dispatch(siteContext_1.Actions.SET_SITE_CONTEXT((0, parse_1.getSiteContext)(req)));
};
exports.siteContext = siteContext;
const flags = (store, req) => {
    const { featureFlags } = req;
    store.dispatch(shared_1.Actions.SET_FEATURE_FLAGS(featureFlags));
};
exports.flags = flags;
const disableEpics = (store, req) => {
    if (req && req.query && req.query.disableEpics) {
        store.dispatch(shared_1.Actions.DISABLE_EPICS(true));
    }
};
exports.disableEpics = disableEpics;
const pagePath = (store, req) => {
    if (req) {
        const { href } = req.location;
        const { path } = (0, parse_1.default)(href);
        store.dispatch(page_1.Actions.SET_PATH(path));
    }
};
exports.pagePath = pagePath;
const defaultMethods = {
    disableEpics: exports.disableEpics,
    flags: exports.flags,
    locale: exports.locale,
    location: exports.location,
    navigation: exports.navigation,
    pagePath: exports.pagePath,
    siteContext: exports.siteContext,
    qaEnabled: exports.qaEnabled,
};
// Abstracting for simple setup of common functions used for all apps
const storeSetup = (store, req, params) => {
    if (store && req) {
        const { exclude } = params || {};
        // Remove Methods we dont want to run
        const defaultSetup = Object.keys(defaultMethods).filter((func) => !exclude || !exclude.includes(func));
        // Loop over List of Functions
        defaultSetup.forEach((key) => (defaultMethods[key] ? defaultMethods[key](store, req) : null));
        // Instana Trace ID - Nothing Fancy, basically set if HEADER is available
        const traceId = req.headers['x-instana-t'];
        if (traceId) {
            store.dispatch(shared_1.Actions.SET_TRACE_ID(traceId));
        }
    }
};
exports.storeSetup = storeSetup;
exports.default = exports.storeSetup;
