import { useEffect, FC, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

type PortalProps = {
  closeModal: () => void
}

const Portal: FC<PropsWithChildren<PortalProps>> = (props) => {
  const { children, closeModal } = props

  const keyPress = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27) {
      closeModal()
    }
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    document.addEventListener('keydown', keyPress)

    return () => {
      try {
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
        document.removeEventListener('keydown', keyPress)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  return ReactDOM.createPortal(children, document.getElementById('modal')!)
}

export default Portal
