import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'

const AuthenticationModals = dynamic(
  import(
    /* webpackChunkName: "authentication" */
    'components/Authentication/Modals/Auth'
  ),
  noSSR
)

export default AuthenticationModals
