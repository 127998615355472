// CONSTANTS
export const Constants = {
  CLEAR_CURATION_BOOKMARK: 'CLEAR_CURATION_BOOKMARK',
  CLEAR_CURATION_HISTORY: 'CLEAR_CURATION_HISTORY',
  DELETE_ARTWORK_BY_CURATOR: 'DELETE_ARTWORK_BY_CURATOR',
  DELETE_ARTWORK_BY_CURATOR_COMPLETE: 'DELETE_ARTWORK_BY_CURATOR_COMPLETE',
  DELETE_ARTWORK_CURATION_STATUS: 'DELETE_ARTWORK_CURATION_STATUS',
  DELETE_ARTWORK_CURATION_STATUS_COMPLETE: 'DELETE_ARTWORK_CURATION_STATUS_COMPLETE',
  FETCH_CURATION_BOOKMARK: 'FETCH_CURATION_BOOKMARK',
  FETCH_CURATION_BOOKMARK_COMPLETE: 'FETCH_CURATION_BOOKMARK_COMPLETE',
  FETCH_CURATION_EMAIL_FORMS: 'FETCH_CURATION_EMAIL_FORMS',
  FETCH_CURATION_EMAIL_FORMS_COMPLETE: 'FETCH_CURATION_EMAIL_FORM_COMPLETE',
  FETCH_CURATORS: 'FETCH_CURATORS',
  FETCH_CURATORS_COMPLETE: 'FETCH_CURATORS_COMPLETE',
  OPEN_CURATION_EMAIL_FORM: 'OPEN_CURATION_EMAIL_FORM',
  SAVE_CURATION_BOOKMARK: 'SAVE_CURATION_BOOKMARK',
  SET_ARTWORK_ID: 'SET_CURATE_ARTWORK_ID',
  SET_CURATE_DATA: 'SET_CURATE_DATA',
  SET_CURATION_BOOKMARK: 'SET_CURATION_BOOKMARK',
  SET_CURATION_HISTORY: 'SET_CURATION_HISTORY',
  SET_CURATORS: 'SET_CURATORS',
  SEND_CURATION_EMAIL_FORM: 'SEND_CURATION_EMAIL_FORM',
  SEND_CURATION_EMAIL_FORM_COMPLETE: 'SEND_CURATION_EMAIL_FORM_COMPLETE',
  UPDATE_ARTWORK_CURATION_ISSAFE: 'UPDATE_ARTWORK_CURATION_ISSAFE',
  UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE: 'UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE',
  UPDATE_ARTWORK_CURATION_STATUS: 'UPDATE_ARTWORK_CURATION_STATUS',
  UPDATE_ARTWORK_CURATION_STATUS_COMPLETE: 'UPDATE_ARTWORK_CURATION_STATUS_COMPLETE',
  UPDATING_CURATION_ACTION: 'UPDATING_CURATION_ACTION',
  UPDATING_CURATION_ARTWORK_ACTION: 'UPDATING_CURATION_ARTWORK_ACTION',
}
// ACTION TYPES
export type ActionTypes =
  | {
      type: typeof Constants.CLEAR_CURATION_BOOKMARK
    }
  | {
      type: typeof Constants.CLEAR_CURATION_HISTORY
    }
  | {
      type: typeof Constants.DELETE_ARTWORK_BY_CURATOR
      payload: string
    }
  | {
      type: typeof Constants.DELETE_ARTWORK_BY_CURATOR_COMPLETE
      payload: Record<string, any> | null | undefined
    }
  | {
      type: typeof Constants.DELETE_ARTWORK_CURATION_STATUS
      payload: string
    }
  | {
      type: typeof Constants.DELETE_ARTWORK_CURATION_STATUS_COMPLETE
    }
  | {
      type: typeof Constants.FETCH_CURATION_BOOKMARK
    }
  | {
      type: typeof Constants.FETCH_CURATION_BOOKMARK_COMPLETE
    }
  | {
      type: typeof Constants.FETCH_CURATION_EMAIL_FORMS
      payload?: string
    }
  | {
      type: typeof Constants.FETCH_CURATION_EMAIL_FORMS_COMPLETE
    }
  | {
      type: typeof Constants.FETCH_CURATORS
    }
  | {
      type: typeof Constants.FETCH_CURATORS_COMPLETE
    }
  | {
      type: typeof Constants.OPEN_CURATION_EMAIL_FORM
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SAVE_CURATION_BOOKMARK
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_ARTWORK_ID
      payload: string
    }
  | {
      type: typeof Constants.SET_CURATE_DATA
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_CURATION_BOOKMARK
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_CURATION_HISTORY
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SET_CURATORS
      payload: Array<Record<string, any>>
    }
  | {
      type: typeof Constants.SEND_CURATION_EMAIL_FORM
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.SEND_CURATION_EMAIL_FORM_COMPLETE
    }
  | {
      type: typeof Constants.UPDATE_ARTWORK_CURATION_ISSAFE
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE
      payload: Record<string, any> | null | undefined
    }
  | {
      type: typeof Constants.UPDATE_ARTWORK_CURATION_STATUS
      payload: Record<string, any>
    }
  | {
      type: typeof Constants.UPDATE_ARTWORK_CURATION_STATUS_COMPLETE
    }
  | {
      type: typeof Constants.UPDATING_CURATION_ACTION
      payload: boolean
    }
  | {
      type: typeof Constants.UPDATING_CURATION_ARTWORK_ACTION
      payload: Record<string, any>
    }
// ACTION CALLS
export const Actions = {
  CLEAR_CURATION_BOOKMARK: (): ActionTypes => ({
    type: Constants.CLEAR_CURATION_BOOKMARK,
  }),
  CLEAR_CURATION_HISTORY: (): ActionTypes => ({
    type: Constants.CLEAR_CURATION_BOOKMARK,
  }),
  DELETE_ARTWORK_BY_CURATOR: (payload: string): ActionTypes => ({
    type: Constants.DELETE_ARTWORK_BY_CURATOR,
    payload,
  }),
  DELETE_ARTWORK_BY_CURATOR_COMPLETE: (
    payload: Record<string, any> | null | undefined
  ): ActionTypes => ({
    type: Constants.DELETE_ARTWORK_BY_CURATOR_COMPLETE,
    payload,
  }),
  DELETE_ARTWORK_CURATION_STATUS: (payload: string): ActionTypes => ({
    type: Constants.DELETE_ARTWORK_CURATION_STATUS,
    payload,
  }),
  DELETE_ARTWORK_CURATION_STATUS_COMPLETE: (): ActionTypes => ({
    type: Constants.DELETE_ARTWORK_CURATION_STATUS_COMPLETE,
  }),
  FETCH_CURATION_BOOKMARK: (): ActionTypes => ({
    type: Constants.FETCH_CURATION_BOOKMARK,
  }),
  FETCH_CURATION_BOOKMARK_COMPLETE: (): ActionTypes => ({
    type: Constants.FETCH_CURATION_BOOKMARK_COMPLETE,
  }),
  FETCH_CURATION_EMAIL_FORMS: (payload?: string): ActionTypes => ({
    type: Constants.FETCH_CURATION_EMAIL_FORMS,
    payload,
  }),
  FETCH_CURATION_EMAIL_FORMS_COMPLETE: (): ActionTypes => ({
    type: Constants.FETCH_CURATION_EMAIL_FORMS_COMPLETE,
  }),
  FETCH_CURATORS: (): ActionTypes => ({
    type: Constants.FETCH_CURATORS,
  }),
  FETCH_CURATORS_COMPLETE: (): ActionTypes => ({
    type: Constants.FETCH_CURATORS_COMPLETE,
  }),
  OPEN_CURATION_EMAIL_FORM: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.OPEN_CURATION_EMAIL_FORM,
    payload,
  }),
  SAVE_CURATION_BOOKMARK: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SAVE_CURATION_BOOKMARK,
    payload,
  }),
  SET_ARTWORK_ID: (payload: string): ActionTypes => ({
    type: Constants.SET_ARTWORK_ID,
    payload,
  }),
  SET_CURATE_DATA: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_CURATE_DATA,
    payload,
  }),
  SET_CURATION_BOOKMARK: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_CURATION_BOOKMARK,
    payload,
  }),
  SET_CURATION_HISTORY: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SET_CURATION_HISTORY,
    payload,
  }),
  SET_CURATORS: (payload: Array<Record<string, any>>): ActionTypes => ({
    type: Constants.SET_CURATORS,
    payload,
  }),
  SEND_CURATION_EMAIL_FORM: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.SEND_CURATION_EMAIL_FORM,
    payload,
  }),
  SEND_CURATION_EMAIL_FORM_COMPLETE: (): ActionTypes => ({
    type: Constants.SEND_CURATION_EMAIL_FORM_COMPLETE,
  }),
  UPDATE_ARTWORK_CURATION_ISSAFE: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_ARTWORK_CURATION_ISSAFE,
    payload,
  }),
  UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE: (
    payload: Record<string, any> | null | undefined
  ): ActionTypes => ({
    type: Constants.UPDATE_ARTWORK_CURATION_ISSAFE_COMPLETE,
    payload,
  }),
  UPDATE_ARTWORK_CURATION_STATUS: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATE_ARTWORK_CURATION_STATUS,
    payload,
  }),
  UPDATE_ARTWORK_CURATION_STATUS_COMPLETE: (): ActionTypes => ({
    type: Constants.UPDATE_ARTWORK_CURATION_STATUS_COMPLETE,
  }),
  UPDATING_CURATION_ACTION: (payload: boolean): ActionTypes => ({
    type: Constants.UPDATING_CURATION_ACTION,
    payload,
  }),
  UPDATING_CURATION_ARTWORK_ACTION: (payload: Record<string, any>): ActionTypes => ({
    type: Constants.UPDATING_CURATION_ARTWORK_ACTION,
    payload,
  }),
}
