import React, { FC, PropsWithChildren } from 'react'
import { Header, Footer } from './Dynamic'
import LayoutConnector, { ConnectorProps as LayoutProps } from 'containers/Layout'
import StyleBase from 'components/StyleBase'
import Loader from 'components/Loader'
import PromoBar from 'components/Promobar'
import { AppLayout } from 'styles/layout'
import LogStore from 'components/LogStore'
import SkipToContent from 'components/Header/SkipToContent'
import GlobalModals from './Modals'

const { APP_ENV } = process.env

type Props = LayoutProps & {
  disableHeader?: boolean
  disableFooter?: boolean
  siteContext?: string
  dataApp: string // pass in app name for STYLING overrides
}

const NavigationLayout: FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    dataApp,
    disableFooter,
    disableHeader,
    iOSWebview,
    isReady,
    siteContext,
  } = props

  if (iOSWebview) {
    return (
      <StyleBase>
        <AppLayout
          data-app={dataApp || 'app'}
          data-sitecontext={siteContext}
          {...(!isReady
            ? {
                [`data-loading`]: isReady,
              }
            : {})}
        >
          <Loader />

          {children}
        </AppLayout>
      </StyleBase>
    )
  }

  return (
    <StyleBase>
      <SkipToContent skipTo='[data-app]' />
      <div>
        <PromoBar />
      </div>
      {!disableHeader && <Header />}

      <AppLayout
        data-app={dataApp || 'app'}
        data-sitecontext={siteContext}
        {...(!isReady
          ? {
              [`data-loading`]: isReady,
            }
          : {})}
      >
        <Loader />

        {children}
      </AppLayout>

      {!disableFooter && <Footer />}

      {/* Development Logger for LOCAL only */}
      {APP_ENV === 'local' && <LogStore />}

      <GlobalModals />
    </StyleBase>
  )
}

export default LayoutConnector(NavigationLayout)
