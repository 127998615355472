import { useStore } from 'react-redux'
import { Actions as ModalActions } from 'actions/shared/modal'

type HookFunctions = {
  showModal: (name: string) => void
  showModalWithData: (name: string, data?: Record<string, any>) => void
}

const useModal = (): HookFunctions => {
  const store = useStore()

  const showModal = (name: string): void => {
    store.dispatch(ModalActions.OPEN_MODAL(name))
  }

  const showModalWithData = (name: string, data: Record<string, any> = {}): void => {
    store.dispatch(ModalActions.OPEN_MODAL_WITH_DATA({ name, data }))
  }

  return {
    showModal,
    showModalWithData,
  }
}
export default useModal
