import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as CartActions } from 'actions/user/cart'
import { getCartCount } from 'selectors/user/cart'
import { Actions as UserActions } from 'actions/user'

const mapStateToProps = createStructuredSelector({
  cartCount: getCartCount,
})

const mapDispatchToProps = (dispatch) => ({
  applyPromo: () => dispatch([CartActions.APPLY_PROMO()]),
  showCart: (show: boolean) => dispatch([CartActions.SHOW_CART(show)]),
  showCartItem: (sku: string) => dispatch([CartActions.SHOW_CART_ITEM(sku)]),
  updateCartCount: (count: number) => dispatch([UserActions.SET_CART(count)]),
})

export type ConnectorProps = {
  applyPromo: (...args: Array<any>) => any
  cartCount: number
  showCart: (...args: Array<any>) => any
  showCartItem: (...args: Array<any>) => any
  updateCartCount: (count: number) => void
}
export default connect(mapStateToProps, mapDispatchToProps)
