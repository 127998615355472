import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getSiteContext } from 'selectors/siteContext'
import type { SiteContext } from 'types/SiteContext'
import 'types/SiteContext'
const mapStateToProps = createStructuredSelector({
  siteContext: getSiteContext,
})
export type ConnectorProps = {
  siteContext: SiteContext
}
export default connect(mapStateToProps)