import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getIsAdmin } from 'selectors/user/session'
import { Actions } from 'actions/shared/modal'
import {
  getModalIsVisible,
  getModalName,
  getModalData,
  getIsCurrentModalAvailable,
} from 'selectors/shared/modal'

const mapStateToProps = createStructuredSelector({
  modalData: getModalData,
  isAdmin: getIsAdmin,
  isModalAvailable: (store, props) => getIsCurrentModalAvailable(props.name)(store),
  isVisible: getModalIsVisible,
  name: getModalName,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(Actions.CLOSE_MODAL()),
  openModal: (payload: string) => dispatch(Actions.OPEN_MODAL(payload)),
  openModalWithData: (name: string, data: any) => {
    dispatch([
      Actions.OPEN_MODAL_WITH_DATA({
        name: name,
        data: data,
      }),
    ])
  },
  resetModal: () => dispatch(Actions.RESET_MODAL()),
})

export type ConnectorProps = {
  closeModal: () => void
  modalData: Record<string, any>
  isAdmin: boolean
  isModalAvailable: boolean
  isVisible: boolean
  name: string
  openModal: (payload: string) => void
  openModalWithData: (name: string, data: any) => void
  resetModal: () => void
}
export default connect(mapStateToProps, mapDispatchToProps)
