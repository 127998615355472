//@ts-nocheck
/*
Ability to reuse across multiple components/forms
We want a single way to control and dispatch form data
Redux and Epics can be too slow for individual input updates ( keyup, onChange )
it has to update data and pass through the App down to component
*/
import { useReducer, useState } from 'react'
import { ValidationRuleset , validateRuleset } from 'lib/validation'
import { uniqueId } from 'lib/helpers'

const reducer = (state: Record<string, any>, data: Record<string, any>) => ({ ...state, ...data })

export type FormDataOptions = {
  formData: Record<string, any>
  formErrors: Record<string, any>
  formId: string
  isValid: boolean
  onChangeCheckbox: (...args: Array<any>) => any
  onChangeInput: (...args: Array<any>) => any
  resetFormData: (...args: Array<any>) => any
  updateFormData: (formData: Record<string, any>) => void
  updateFormErrors: (errors: Record<string, string>) => void
  validateFormData: () => boolean
}
type Props = {
  initialState: Record<string, any>
  formRules?: ValidationRuleset
}

const FormDataHook = (props: Props): FormDataOptions => {
  const { initialState, formRules } = props
  const [formData, updateFormData] = useReducer(reducer, initialState)
  const [formErrors, updateFormErrors] = useState({})
  // Create a Unique Form ID, used for Duplicating forms and giving Components a unique KEY
  const [formId] = useState(uniqueId())

  const resetFormData = (): void => {
    updateFormData(initialState)
    updateFormErrors({})
  }

  const onChangeInput = (ev: React.SyntheticEvent<HTMLElement>): void => {
    ev.preventDefault()
    const { name, value } = ev.target
    updateFormData({
      [name]: value,
    })
  }

  const onChangeCheckbox = (ev: React.SyntheticEvent<HTMLElement>): void => {
    const { name, value, checked } = ev.target

    if (Array.isArray(formData[name])) {
      const values = [...formData[name]]
      updateFormData({
        [name]: checked ? [...values, value] : values.filter((id) => id !== value),
      })
    } else {
      updateFormData({
        [name]: checked ? value : '',
      })
    }
  }

  const isValid = (errors: Record<string, any>): boolean => Object.keys(errors).length === 0

  const validateFormData = async (): Promise<boolean> => {
    let errors = []

    try {
      // No Rules Specified - We want to throw ERROR
      // you should not validate if no rules
      if (!formRules) {
        throw new Error('NoRules')
      }

      await validateRuleset(formRules, formData, {
        allRules: true,
      })
    } catch (validationErrors) {
      if (validationErrors.message === 'NoRules') {
        errors = [
          {
            message: 'No Rules Specified',
            prop: 'rules',
            value: '',
          },
        ]
      } else {
        errors = validationErrors
      }
    }

    // Lets push Array<Errors> into an easier to read Object format
    // Format: [{ message, prop, value }] => { [prop] : message }
    // console.log({ formRules, formData })
    // console.log({ errors })
    const updatedFormErrors = Array.isArray(errors)
      ? errors.reduce((data, { message, prop }) => ({ ...data, [prop]: message }), {})
      : []
    updateFormErrors(updatedFormErrors)
    // Do we have ERRORS? Hopefully not...
    return isValid(updatedFormErrors)
  }

  return {
    formData,
    formErrors,
    formId,
    isValid: isValid(formErrors),
    onChangeCheckbox,
    onChangeInput,
    resetFormData,
    updateFormData,
    updateFormErrors,
    validateFormData,
  }
}

export default FormDataHook
