import galleryAPI, { GalleryResponse, putOptions } from 'apis/helpers/galleryAPI'

const getMeasurementUnit = (val: string): 'INCH' | 'CENTIMETER' => {
  if (val.search(/^in/gi) > -1) {
    return 'INCH'
  }
  return 'CENTIMETER'
}

type UpdateMyUserPreferences = {
  countryCode: string
  currency: string
  measurementUnit: string
}

const transformPayload = (payload: UpdateMyUserPreferences): Record<string, any> => {
  const { countryCode, currency, measurementUnit } = payload
  return {
    preferred_destination_country: countryCode,
    preferred_currency: currency,
    preferred_unit_of_measurement: getMeasurementUnit(measurementUnit),
  }
}

export const updateMyUserPreferences = async (
  data: UpdateMyUserPreferences
): Promise<GalleryResponse> => {
  const body = transformPayload(data)
  const options = { ...putOptions, body: JSON.stringify(body) }
  const json = await galleryAPI.fetchJSON({
    name: 'updateMyUserPreferences',
    url: `/user/preferences`,
    options,
  })
  return json
}
