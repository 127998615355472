import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'

const PreferencesModal = dynamic(
  import(
    /* webpackChunkName: "preferences" */
    'components/Preferences/Modal/Preferences'
  ),
  noSSR
)

export default PreferencesModal
