// CONSTANTS
export const Constants = {
  SET_QA_ENABLED: 'SET_QA_ENABLED',
}
// ACTION TYPES
export type ActionTypes = {
  type: typeof Constants.SET_QA_ENABLED
  payload: string
}
// ACTION CALLS
export const Actions = {
  SET_QA_ENABLED: (payload: string): ActionTypes => ({
    type: Constants.SET_QA_ENABLED,
    payload,
  }),
}
