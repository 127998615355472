import React, { FC, useState } from 'react'
import InputLabel from 'components/SA/FormElements/InputLabel'
import HideIcon from 'svg/hide-eye.svg'
import ShowIcon from 'svg/show-eye.svg'
import styles from '../Modals/Auth.module.scss'

type PasswordInputProps = {
  name?: string
  onChange: (...args: Array<any>) => any
  password: string
  passwordError: string
  placeholder?: string
  title: string
  styleOverride?: string
}

const PasswordInput: FC<PasswordInputProps> = (props) => {
  const { name, onChange, password, passwordError, placeholder, title, styleOverride } = props
  const [showPassword, handleShowPassword] = useState(false)

  const toggleShowPassword = () => {
    return handleShowPassword(!showPassword)
  }

  return (
    <div className={`${styles.passwordInput} ${styleOverride}`}>
      <InputLabel
        styleOverride={styleOverride}
        autoComplete='nope'
        error={passwordError}
        name={name || 'password'}
        onChange={onChange}
        placeholder={placeholder || 'Password'}
        title={title}
        type={showPassword ? 'text' : 'password'}
        value={password}
      />

      <div className={styles.row}>
        {showPassword ? (
          <HideIcon onClick={toggleShowPassword} title='hide password' />
        ) : (
          <ShowIcon onClick={toggleShowPassword} title='show password' />
        )}
      </div>
    </div>
  )
}

export default PasswordInput
