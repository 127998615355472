import React from 'react'
import { Title, Text, Message } from './styles'
type Props = {
  hideStatus?: boolean
  statusCode?: number
  errorEventId?: string
  message?: string
  text?: string
}
export default (props: Props): React$ElementRef<any> => {
  const { errorEventId, hideStatus, statusCode, message, text } = props
  return (
    <Message>
      <Title>{message || 'Oops! The page you requested does not exist.'}</Title>
      {text && (
        <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      )}
      {!hideStatus && (
        <Text>
          {statusCode
            ? `An error ${statusCode} occurred on the server`
            : 'An error occurred on the client'}
        </Text>
      )}
      {errorEventId && <Text small>id: {errorEventId}</Text>}
    </Message>
  )
}