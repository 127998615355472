import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { isAuthenticated } from 'selectors/user/session'
import {
  getAvailableCurrenciesData,
  getLocaleCity,
  getLocaleCountryCode,
  getLocaleCountryConflict,
  getLocaleRegionCode,
} from 'selectors/shared/locale'
import {
  getUserPreferencesSelectedCurrency,
  getUserPreferencesCurrency,
} from 'selectors/user/cookie'
import { Actions } from 'actions/shared/locale'
import type { LocaleCurrency } from 'types/Shared/Locale'
import 'types/Shared/Locale'
const mapStateToProps = createStructuredSelector({
  availableCurrencies: getAvailableCurrenciesData,
  isAuthenticated,
  localeCity: getLocaleCity,
  localeCountryCode: getLocaleCountryCode,
  localeCountryConflict: getLocaleCountryConflict,
  localeRegionCode: getLocaleRegionCode,
  selectedCurrency: getUserPreferencesSelectedCurrency,
  selectedLocale: getUserPreferencesCurrency,
})

const mapDispatchToProps = (dispatch) => ({
  changeLocale: (payload: string) => dispatch(Actions.CHANGE_LOCALE(payload)),
})

export type ConnectorProps = {
  availableCurrencies: Array<LocaleCurrency>
  changeLocale: (...args: Array<any>) => any
  isAuthenticated: boolean
  localeCity: string
  localeCountryCode: string
  localeCountryConflict: boolean
  localeRegionCode: string
  selectedCurrency: LocaleCurrency
  selectedLocale: string
}
export default connect(mapStateToProps, mapDispatchToProps)
