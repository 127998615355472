import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getMessages, hasMessages, getType } from 'selectors/shared/flashMessage'
import { Actions, ActionTypes as FlashMessageActionTypes } from 'actions/shared/flashMessage'
import { FlashMessage, FlashMessages, FlashMessageType } from 'types/Shared/FlashMessage'
import { ReduxState } from 'reducers/index'

const mapStateToProps = createStructuredSelector({
  available: hasMessages,
  messages: getMessages,
  type: getType,
})

const mapDispatchToProps = (dispatch: (...args: Array<any>) => any) => ({
  addMessage: (payload: FlashMessage) => dispatch(Actions.ADD(payload)),
  clearMessage: () => dispatch(Actions.CLEAR()),
})

export type ConnectorProps = {
  addMessage: (payload: FlashMessage) => void
  available: boolean
  clearMessage: () => void
  messages: FlashMessages
  type: FlashMessageType
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector
