/* Pulled from https://usehooks.com/useScript/ */
import { useState, useEffect } from 'react'
const LOADING = 'loading'
const IDLE = 'idle'
const ERROR = 'error'
const READY = 'ready'
export const STATUS = {
  LOADING,
  IDLE,
  ERROR,
  READY,
}
type Status = typeof LOADING | typeof IDLE | typeof ERROR | typeof READY | string | null
type Options = {
  name: string // Required to check if SCRIPT exist
  [key: string]: any // async, src, text - are optional
}

// Hook
const useScript = (options: Options): Status => {
  const { src, text, name } = options
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<Status>(src ? LOADING : IDLE)
  useEffect(
    () => {
      if (window && document) {
        // Fetch existing script element by src
        // It may have been added by another instance of this hook
        let script: HTMLElement | null = document.querySelector(`script[data-name="${name}"]`)

        if (!script) {
          // Create script
          script = document.createElement('script')
          script.setAttribute('data-status', LOADING)

          // Allow CUSTOM options to be added
          if (options) {
            Object.entries(options).forEach(([key, value]) => {
              if (key && value && script) {
                script[key] = value
              }
            })
          }

          // Add script to document body
          document.body && document.body.appendChild(script)

          // Store status in attribute on script
          // This can be read by other instances of this hook
          const setAttributeFromEvent = (event: Event) => {
            if (script) {
              script.setAttribute('data-status', event.type === 'load' ? READY : ERROR)
            }
          }

          script.addEventListener('load', setAttributeFromEvent)
          script.addEventListener('error', setAttributeFromEvent)
        } else {
          // Grab existing script status from attribute and set to state.
          setStatus(script.getAttribute('data-status'))
        }

        // Script event handler to update status in state
        // Note: Even if the script already exists we still need to add
        // event handlers to update the state for *this* hook instance.
        const setStateFromEvent = (event: Event) => {
          setStatus(event.type === 'load' ? READY : ERROR)
        }

        // Add event listeners
        script.addEventListener('load', setStateFromEvent)
        script.addEventListener('error', setStateFromEvent)
        // Remove event listeners on cleanup
        return () => {
          if (script) {
            script.removeEventListener('load', setStateFromEvent)
            script.removeEventListener('error', setStateFromEvent)
            document.body && document.body.removeChild(script)
          }
        }
      } else {
        return () => {}
      }
    },
    [src, text] // Only re-run effect if script src or text changes
  )
  return status
}

export default useScript
