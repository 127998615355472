import { createSelector } from 'reselect'
import { MeasurementUnit, Role, UserSession } from 'types/User/Session'
import { getCustomerEmail } from '../customer'
import { getUserOrNull } from './helpers'
export const getSession = createSelector([getUserOrNull], (user) => user?.session || {})
export const getSessionOrNull = createSelector([getUserOrNull], (user) => user?.session || null)
// This will be a FLAG to indicate if the USER has CHECKED for SESSION from CLIENT SIDE
export const isSessionSet = createSelector(
  [getSessionOrNull],
  (session): boolean => session?.isSet || false
)
export const isAuthenticated = createSelector(
  [getSessionOrNull],
  (session): boolean => session?.isAuthenticated ?? false
)
export const isKnownUser = createSelector(
  [getSessionOrNull],
  (session): boolean => session?.isKnownUser ?? false
)
export const getIsTakeover = createSelector([getSessionOrNull], (session): {
  adminId: number
  fullName: string
} | null =>
  session?.isAuthenticated && session.adminId
    ? {
        adminId: session.adminId,
        fullName: session.adminFullName,
      }
    : null
)
export const getUserID = createSelector([getSessionOrNull], (session): number =>
  session?.isAuthenticated ? session.userId : 0
)
export const getUserEmail = createSelector([getSessionOrNull], (session): string =>
  session?.isAuthenticated ? session.email : ''
)
export const getUserTypeId = createSelector([getSessionOrNull], (session): number =>
  session?.isAuthenticated ? session.userTypeId : 0
)
export const getUserRole = createSelector(
  [getSessionOrNull],
  (session): Role => session?.userRole || 'guest'
)
export const getIsArtist = createSelector(
  [getUserRole],
  (userRole: Role): boolean => userRole === 'artist' // OPTIONAL way: session.userTypeId === 2
)
export const getMeasurementUnit = createSelector(
  [getSessionOrNull],
  (session): MeasurementUnit => session?.measurementUnit || 'in'
)
export const getFullName = createSelector([getSessionOrNull], (session):
  | string
  | null
  | undefined => (session?.isAuthenticated ? `${session.firstName} ${session.lastName}` : ''))

export const getFirstAndLastName = createSelector([getSessionOrNull], (session) => ({
  firstName: (session as UserSession)?.firstName ?? '',
  lastName: (session as UserSession)?.lastName ?? '',
}))
export const getFirstName = createSelector([getSessionOrNull], (session): string | null =>
  session?.isAuthenticated ? session?.firstName : null
)
export const getUserInitials = createSelector(
  [getFirstAndLastName],
  ({ firstName, lastName }): string =>
    firstName && lastName ? `${firstName.substring(0, 1)}${lastName.substring(0, 1)}` : ''
)
export const getProfileUrl = createSelector([getSessionOrNull], (session): string => {
  if (!session || !session.isAuthenticated) {
    return ''
  }

  if (session.userTypeId === 1) {
    return `/u/${session.userId}`
  }

  return session.userName ? `/${session.userName}` : `/account/profile/${session.userId}`
})
export const getIsAdmin = createSelector([getSessionOrNull], (session): boolean =>
  session && session.isAuthenticated ? session.isAdmin : false
)
export const getIsLimitedArtist = createSelector([getSessionOrNull], (session): boolean =>
  session?.isAuthenticated ? session.isLimitedArtist : false
)
export const getLocalizationCountry = createSelector(
  [getSessionOrNull],
  (session): string => session?.localizationCountry || 'US'
)
export const getUserToken = createSelector([getSessionOrNull], (session): string => {
  if (session) {
    return `${session.isAuthenticated ? session.userId : session.guestId || ''}` || 'Anonymous'
  }

  return 'Anonymous'
})
export const getInstanaUserOrGuestSession = createSelector(
  [getUserID, getFullName, getUserEmail],
  (userId, userName, userEmail): Record<string, any> => {
    return {
      userId: userId || 'Anonymous',
      userName: userName || 'Anonymous',
      userEmail: userEmail || 'Anonymous',
    }
  }
)
export const getFavorites = createSelector(
  [getSessionOrNull],
  (session): Array<number> => session?.favorites || []
)
export const getSessionDatalayer = createSelector(
  [getUserID, getUserRole, getUserEmail, getFirstName, getCustomerEmail],
  (userId, userRole, userEmail, firstName, customerEmail) => {
    return {
      'user category': userRole || 'guest',
      'user email': userEmail || customerEmail || '',
      // Default to customer data if available ( No Session )
      'user id': userId || '',
      'user first name': firstName || '',
    }
  }
)
